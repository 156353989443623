import { Transition as MantineTransition, TransitionProps } from '@mantine/core';

type Props = Omit<TransitionProps, 'children' | 'transition'> & {
  children: JSX.Element;
  transition?: TransitionProps['transition'];
};

export const Transition = ({ children, transition = 'fade', duration = 600, ...props }: Props) => {
  return (
    <MantineTransition {...props} transition={transition} duration={duration}>
      {(styles) => <div style={styles}>{children}</div>}
    </MantineTransition>
  );
};
