import { Middleware } from 'redux';
import { isRejectedWithValue, MiddlewareAPI } from '@reduxjs/toolkit';
import { setApiError } from './app-slice';

export const rtkQueryErrorSetterMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (
  action,
) => {
  if (isRejectedWithValue(action)) {
    api.dispatch(setApiError({ isError: true, message: action.payload?.data?.message ?? '' }));
  }

  return next(action);
};
