import { useEffect } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { NavigationOutput } from './features/navigation/navigation-output.feature';
import { useApi } from './hooks/useApi.hook';
import { ApiErrorBoundaryFeature } from './features/error-boundary/api-error-boundary.feature';
import { useRequiredDataLoader } from './hooks/redux/useRequriedDataLoader';
import { useAppSelector } from './hooks/useAppSelector';
import { Preloader } from './components/preloader';

function App() {
  useRequiredDataLoader();
  const { handleRequest, ServiceApi } = useApi();
  const isBlurVisible = useAppSelector((state) => state.app.isBlurVisible);

  const dispatchLogoutEvent = () => {
    handleRequest(ServiceApi.analyticsControllerLogout());
  };

  useEffect(() => {
    window.addEventListener('beforeunload', dispatchLogoutEvent);

    return () => {
      window.removeEventListener('beforeunload', dispatchLogoutEvent);
    };
  }, []);

  return (
    <div className='App'>
      <LoadingOverlay visible={isBlurVisible} overlayBlur={10} overlayColor={'black'} />

      <NavigationOutput />

      <ApiErrorBoundaryFeature />

      <Preloader />
    </div>
  );
}

export default App;
