/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SocialMediaDto } from './SocialMediaDto';
import type { StudyClassDto } from './StudyClassDto';
import type { UserProjectDto } from './UserProjectDto';

export type UserDto = {
    id: string;
    fullName: string;
    email: string;
    password: string;
    role: UserDto.role;
    mentorEmail?: string;
    studyClass?: StudyClassDto;
    projects: Array<UserProjectDto>;
    createdAt: string;
    banned: boolean;
    registrationConfirmed?: boolean;
    lastDayAccess?: string;
    phoneNumber?: string;
    country?: string;
    availableStudyClasses?: Array<StudyClassDto>;
    gender?: UserDto.gender;
    finishedCourses?: number;
    socialMedia?: Array<SocialMediaDto>;
    dateOfBirth?: string;
    avatarUrl?: string;
}

export namespace UserDto {

    export enum role {
        ADMIN = 'admin',
        STUDENT = 'student',
        MENTOR = 'mentor',
    }

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}