type Props = {
  title: string;
  value: string;
  classModifier: string;
};

export const StatsItem = ({ value, title, classModifier }: Props) => {
  return (
    <li className={`profile-stats__item ${classModifier}`}>
      <h3 className={`profile-stats__title ${classModifier}`}>
        <span className='profile-stats__name'>{title}</span>
        <span className='profile-stats__info'>{value}</span>
      </h3>
    </li>
  );
};
