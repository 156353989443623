/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CriteriaProgressItemDto = {
    criterionId: string;
    status: CriteriaProgressItemDto.status;
    changedStatusAt?: string;
    approved: boolean;
    approvedAt?: string;
}

export namespace CriteriaProgressItemDto {

    export enum status {
        TODO = 'todo',
        DONE = 'done',
        WARNING = 'warning',
    }


}