import { Normalized } from './shared.types';

export function formatDate(date: Date) {
  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  return `${date.getDate()}.${formattedMonth}.${date.getFullYear()}`;
}

export function getDaysDiff(date: Date) {
  const now = new Date();
  date.setUTCHours(0);
  now.setUTCHours(0);

  const diffTime = now.getTime() - date.getTime();

  return diffTime / (1000 * 3600 * 24);
}

export function normalizeArray<T extends Object, K extends keyof T = keyof T>(
  array: T[],
  key: K,
): Normalized<T> {
  return array.reduce((acum, item) => {
    const idValue = String(item[key]);
    acum[idValue] = item;
    return acum;
  }, {} as Record<string, T>);
}
