import { useCallback, useState } from 'react';
import { ApiError, AuthApi, OpenAPI, ServiceApi, UserApi } from '../openapi';
import { envs, getAuthData } from '../shared';
import { useAuth } from './useAuth';

OpenAPI.BASE = envs.serverUrl;
const authData = getAuthData();

export const useApi = () => {
  const { token, wasLoggedIn } = useAuth();
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  OpenAPI.HEADERS = {
    Authorization: `Bearer ${wasLoggedIn ? token : authData?.token}`,
  };

  const handleRequest = useCallback(
    async function <T>(request: Promise<T>) {
      setIsLoading(true);

      try {
        const response = await request;
        setError(undefined);
        return response;
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [token],
  );

  const dismissError = useCallback(() => {
    setError(undefined);
  }, []);

  return { dismissError, error, isLoading, handleRequest, ServiceApi, UserApi, AuthApi };
};

export const setOpenapiToken = (token: string) => {
  OpenAPI.HEADERS = {
    Authorization: `Bearer ${token}`,
  };
};
