import { useEffect, useState } from 'react';
import { AuthForm } from '../../components/forms/auth-form/auth-form';
import { getAuthData, setAuthData } from '../../shared';
import { AuthErrorType } from '../../components/forms/form-error';
import { useApi } from '../../hooks/useApi.hook';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { setToken } from '../user/redux/user-slice';
import { useAuth } from '../../hooks/useAuth';

export const AuthFeature = () => {
  const dispatch = useAppDispatch();
  const { wasLoggedIn } = useAuth();

  const [errorInfo, setErrorInfo] = useState<{ isValid: boolean; errorType: AuthErrorType }>({
    isValid: true,
    errorType: '',
  });
  const { handleRequest, AuthApi, isLoading } = useApi();

  useEffect(() => {
    const authData = getAuthData();
    if (!authData || wasLoggedIn) {
      return;
    }
  }, []);

  async function login(email: string, password: string) {
    const response = await handleRequest(
      AuthApi.authControllerLogin({ requestBody: { username: email, password } }),
    );

    if (!response) {
      setErrorInfo({ isValid: false, errorType: 'credentials' });
      return;
    }

    setAuthData({ token: response.token });

    dispatch(setToken(response.token));
  }

  function resetErrorInfo() {
    setErrorInfo({ isValid: true, errorType: '' });
  }

  return (
    <AuthForm
      onValidSubmit={login}
      isLoading={isLoading}
      isValid={errorInfo.isValid}
      errorType={errorInfo.errorType}
      resetErrorInfo={resetErrorInfo}
    />
  );
};
