import { Link } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';

import './error.page.scss';

export const ErrorPage = () => {
  const { renderTranslation } = useTranslation();

  return (
    <main className='page error-page'>
      <div className='container'>
        <section className='error-section'>
          <div className='error-section__image'></div>
          <div className='error-section__content'>
            <h1 className='box main-title'>
              {renderTranslation({
                ru: 'Ошибка, такой страницы не существует.',
                uk: 'Помилка, такої сторінки не існує.',
              })}
            </h1>
            <Link to='/' className='btn btn--green btn--lg'>
              {renderTranslation({ ru: 'Вернуться на главную', uk: 'Повернутися на головну' })}
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};
