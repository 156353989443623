import Logo from '../../assets/logo1.svg';
import { SocialList } from '../social/social-list';
import { Menu } from './menu/menu';

import './pages-footer.scss';

const projectStartYear = 2021;
const currentYear = new Date().getFullYear();

export const PagesFooter = () => {
  return (
    <footer className='pages-footer'>
      <div className='container'>
        <div className='pages-footer__inner d-flex'>
          <a href='#' className='logo pages-footer__logo'>
            <img src={Logo} alt='логотип mentors-help' className='logo__img' />
          </a>

          <Menu classes='pages-footer__menu' />

          <SocialList newClass='pages-footer__social' />
        </div>
        <div className='copy text-center'>
          Mentors-help.online <br /> {projectStartYear}-{currentYear}
        </div>
      </div>
    </footer>
  );
};
