/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginDto } from '../models/LoginDto';
import type { LoginResponseDto } from '../models/LoginResponseDto';
import type { LoginToLinkedAccountDto } from '../models/LoginToLinkedAccountDto';
import type { RegisterDto } from '../models/RegisterDto';
import type { UserDto } from '../models/UserDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AuthApi {

    /**
     * @returns LoginResponseDto 
     * @throws ApiError
     */
    public static authControllerLogin({
requestBody,
}: {
requestBody: LoginDto,
}): CancelablePromise<LoginResponseDto> {
        return __request({
            method: 'POST',
            path: `/api/auth`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserDto 
     * @throws ApiError
     */
    public static authControllerRegister({
requestBody,
}: {
requestBody: RegisterDto,
}): CancelablePromise<UserDto> {
        return __request({
            method: 'POST',
            path: `/api/auth/register`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LoginResponseDto 
     * @throws ApiError
     */
    public static authControllerLoginToLinkedAccount({
requestBody,
}: {
requestBody: LoginToLinkedAccountDto,
}): CancelablePromise<LoginResponseDto> {
        return __request({
            method: 'POST',
            path: `/api/auth/linked-account`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}