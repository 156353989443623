import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';

type Props = {
  isUnauthorizedError: boolean;
};

export const ApiErrorContent = ({ isUnauthorizedError }: Props) => {
  const { renderTranslation } = useTranslation();

  return (
    <p>
      {isUnauthorizedError
        ? renderTranslation({
            ru: 'Произошла ошибка при попытке входа, пожалуйста, авторизуйтесь снова',
            uk: 'Сталася помилка при спробі входу, будь ласка, авторизуйтесь знову',
          })
        : renderTranslation({
            ru:
              'Произошла ошибка при загрузке данных, попробуйте обновить страницу или связаться с',
            uk:
              'Сталася помилка при завантаженні даних, спробуйте оновити сторінку або зв`язатися з',
          })}

      {!isUnauthorizedError && (
        <a className='link-highlight' href='https://t.me/mentors_help_admin' target='_blank'>
          {' '}
          <b>
            {renderTranslation({
              ru: 'администратором платформы',
              uk: 'адміністратором платформи',
            })}
          </b>
        </a>
      )}
    </p>
  );
};
