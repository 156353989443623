/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RequirementCriteriaDto = {
    criteriaNumbers: Array<number>;
    category: RequirementCriteriaDto.category;
}

export namespace RequirementCriteriaDto {

    export enum category {
        COMMON = 'common',
        HTML = 'html',
        STYLES = 'styles',
        RESPONSIVE = 'responsive',
    }


}