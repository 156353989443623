import React, { useEffect, useState } from 'react';
import { useCurrentProjectNav } from '../../../hooks/projects/useCurrentProjectNav';
import { useTranslation } from '../../../hooks/useTranslation';
import { DetailsListItem } from './details-list-item';
import { tabsButtons } from './tabs-data';
import { TabItem } from './tab-item';
import { Tab } from './types';

export const ProjectLessonDetails = () => {
  const { renderTranslation } = useTranslation();
  const { currentLesson } = useCurrentProjectNav();

  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(Tab.noTab);

  const onClickHandler = () => {
    setIsOpen(!isOpen);
    setActiveTab(Tab.noTab);
  };

  const changeTabHandler = (tab: Tab) => {
    if (tab === activeTab) {
      setActiveTab(Tab.noTab);
    }

    setActiveTab(tab);
  };

  // TODO: Rework to react-style
  useEffect(() => {
    const closeOutside = (e: any) => {
      let $requirementsEl = document.querySelectorAll('.requirements-list__content *');
      let $scippedEl;

      for (let i = 0; i < $requirementsEl.length; i++) {
        if (e.target === $requirementsEl[i]) {
          $scippedEl = $requirementsEl[i];
        }
      }

      if (
        !e.target.classList.contains('requirements__btn') &&
        !e.target.classList.contains('projects-nav__btn') &&
        !e.target.classList.contains('criteria-nav__btn') &&
        !e.target.classList.contains('criteria-group__btn') &&
        !(e.target.classList.value.indexOf('requirements__') >= 0) &&
        !(e.target.classList.value.indexOf('requirements-list') >= 0) &&
        !$scippedEl
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', closeOutside);

    return () => document.removeEventListener('click', closeOutside);
  }, []);

  const tabsButtonsToRender = tabsButtons.map(({ tab, translation }) => {
    if (
      !(
        (tab === Tab.hints && !currentLesson.hints) ||
        (tab === Tab.plus && !currentLesson.additionalTasks)
      )
    ) {
      return (
        <TabItem
          activeTab={activeTab}
          tabValue={tab}
          title={renderTranslation(translation)}
          onClick={changeTabHandler}
          key={tab}
        />
      );
    } else {
      return null;
    }
  });

  return (
    <section className={`requirements ${activeTab} ${isOpen ? 'open' : ''}`}>
      <div className='requirements__head'>
        <button
          className={`btn btn--lg btn--green requirements__btn ${isOpen ? 'active' : ''}`}
          type='button'
          onClick={onClickHandler}
          title={renderTranslation({
            ru: 'Открыть/Закрыть требования к заданию',
            uk: 'Відкрити/Закрити вимоги к завданню',
          })}>
          <span className='sr-only'>
            {renderTranslation({
              ru: 'Открыть/Закрыть требования к заданию',
              uk: 'Відкрити/Закрити вимоги к завданню',
            })}
          </span>
        </button>

        <h2 className='requirements__title'>
          {renderTranslation({ ru: 'Требования к заданию', uk: 'Вимоги к завданню' })} №{' '}
          <span className='requirements__num'>{currentLesson.number}</span>
        </h2>
      </div>

      <div className='requirements__inner'>
        <ul className='requirements__nav'>{tabsButtonsToRender}</ul>
        <ul className='requirements-list'>
          <DetailsListItem
            title={renderTranslation({ ru: 'Задачи', uk: 'Задачі' })}
            html={renderTranslation(currentLesson.taskDescription)}
            type={Tab.tasks}
          />
          <DetailsListItem
            title={renderTranslation({ ru: 'Верстаем', uk: 'Верстаємо' })}
            html={renderTranslation(currentLesson.markupDescription)}
            type={Tab.work}
          />
          {currentLesson.hints && (
            <DetailsListItem
              title={'Подсказки'}
              html={renderTranslation(currentLesson.hints)}
              type={Tab.hints}
            />
          )}
          {currentLesson.additionalTasks && (
            <DetailsListItem
              title={renderTranslation({ ru: 'Дополнительные задания', uk: "Додаткові завдання'" })}
              html={renderTranslation(currentLesson.additionalTasks)}
              type={Tab.plus}
            />
          )}
        </ul>
      </div>
    </section>
  );
};
