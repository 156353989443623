import { Call } from '../../components/call/call';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useTranslation } from '../../hooks/useTranslation';

import './cooperation.page.scss';

export const CooperationPage = () => {
  const { renderTranslation } = useTranslation();
  useDocumentTitle(`Сотрудничество`);

  return (
    <main className='page content-page cooperation-page'>
      <div className='container'>
        <div className='box'>
          <div className='page__head d-flex cooperation-page__head'>
            <h1 className='main-title main-title--indent'>
              {renderTranslation({ ru: 'Сотрудничество', uk: 'співробітництво' })}
            </h1>
            <Call
              newClass='cooperation-page__call'
              callLink='https://t.me/mentors_help_admin'
              callHint={renderTranslation({
                ru: 'Написть администратору',
                uk: 'Написати адміністратору',
              })}
              callText={renderTranslation({ ru: 'Написать нам!', uk: 'Написати нам!' })}
            />
          </div>

          <div className='content cooperation-page__content'>
            <h2 className='sr-only'>
              {renderTranslation({
                ru: 'Список предложений сотрудничества с нами',
                uk: 'Список пропозицій співпраці з нами',
              })}
            </h2>

            <ul className='cooperation-list'>
              <li className='cooperation-list__item cooperation-list__item--tutor'>
                <h3 className='cooperation-list__title'>
                  {renderTranslation({ ru: 'Стань наставником', uk: 'Стань наставником' })}
                </h3>
                <p className='cooperation-list__content'>
                  {renderTranslation({
                    ru:
                      'Если ты уже окончил курсы по вёрстке или фронтенду и хочешь помогать новичкам - присоединяйся к нашей команде наставников',
                    uk:
                      'Якщо ти вже закінчив курси з верстки чи фронтенду і хочеш допомагати новачкам – приєднуйся до нашої команди наставників',
                  })}
                </p>
              </li>
              <li className='cooperation-list__item cooperation-list__item--course'>
                <h3 className='cooperation-list__title'>
                  {renderTranslation({ ru: 'Добавь свой курс', uk: 'Додай свій курс' })}
                </h3>
                <p className='cooperation-list__content'>
                  {renderTranslation({
                    ru:
                      'У тебя есть курс по фронтенду и нужна поддержка наставников - можешь получить профессиональную помощь',
                    uk:
                      'У тебе є курс з фронтенду і потрібна підтримка наставників - можеш отримати професійну допомогу',
                  })}
                </p>
              </li>
              <li className='cooperation-list__item cooperation-list__item--matherials'>
                <h3 className='cooperation-list__title'>
                  {renderTranslation({
                    ru: 'Поделись материалами',
                    uk: 'Поділися матеріалами',
                  })}
                </h3>
                <p className='cooperation-list__content'>
                  {renderTranslation({
                    ru:
                      'Нашел интересные и полезные материалы, которые помогли тебе при обучении - присылай нам, рассмотрим и добавим к нашим материалам',
                    uk:
                      'Знайшов цікаві та корисні матеріали, які допомогли тобі при навчанні – надсилай нам, розглянемо та додамо до наших матеріалів',
                  })}
                </p>
              </li>
              <li className='cooperation-list__item cooperation-list__item--ideas'>
                <h3 className='cooperation-list__title'>
                  {renderTranslation({
                    ru: 'Предлагай свои варианты',
                    uk: 'Пропонуй свої варіанти',
                  })}
                </h3>
                <p className='cooperation-list__content'>
                  {renderTranslation({
                    ru: 'Если знаешь, чем помочь - будем рады свежим идеям и предложениям',
                    uk: 'Якщо знаєш, чим допомогти - будемо раді свіжим ідеям та пропозиціям\n',
                  })}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
};
