/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequirementDto } from '../models/RequirementDto';
import type { UpdateRequirementDto } from '../models/UpdateRequirementDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ServiceApi {

    /**
     * @returns any 
     * @throws ApiError
     */
    public static appControllerIndex(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api`,
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static analyticsControllerLogout(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/analytics`,
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static requirementControllerFindAllByFigmaTemplate({
figmaTemplateTitle,
}: {
figmaTemplateTitle: 'freshok' | 'portfolio' | 'simple food',
}): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/requirement/category`,
            query: {
                'figmaTemplateTitle': figmaTemplateTitle,
            },
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static requirementControllerFindAll(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/requirement`,
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static requirementControllerCreateRequirement({
requestBody,
}: {
requestBody: RequirementDto,
}): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/requirement`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static requirementControllerUpdateRequirement({
figmaTemplateTitle,
lessonNumber,
requestBody,
}: {
figmaTemplateTitle: 'freshok' | 'portfolio' | 'simple food',
lessonNumber: number,
requestBody: UpdateRequirementDto,
}): CancelablePromise<any> {
        return __request({
            method: 'PATCH',
            path: `/api/requirement`,
            query: {
                'figmaTemplateTitle': figmaTemplateTitle,
                'lessonNumber': lessonNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static requirementControllerDeleteRequirement({
figmaTemplateTitle,
lessonNumber,
}: {
figmaTemplateTitle: 'freshok' | 'portfolio' | 'simple food',
lessonNumber: number,
}): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/requirement`,
            query: {
                'figmaTemplateTitle': figmaTemplateTitle,
                'lessonNumber': lessonNumber,
            },
        });
    }

}