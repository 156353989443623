import './avatar.scss';

type Props = {
  username?: string;
  classes?: string;
  img: string;
};

export const Avatar = ({ username, classes, img }: Props) => {
  const userName = username ? <figcaption className='avatar__name'>{username}</figcaption> : null;

  return (
    <figure className={`avatar ${classes}`}>
      <img className='avatar__image' src={img} alt={`Фото ${username ?? 'aватара'} `} />
      {userName}
    </figure>
  );
};
