import { useEffect, useRef } from 'react';

export const useClickOutside = (handler: () => void) => {
  let domNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let maybeHandler = (event: MouseEvent) => {
      if (domNode.current !== null) {
        if (!domNode.current.contains(event.target as Node)) {
          handler();
        }
      }
    };

    document.addEventListener('mousedown', maybeHandler);

    return () => {
      document.removeEventListener('mousedown', maybeHandler);
    };
  }, []);

  return domNode;
};
