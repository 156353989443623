/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Translation } from './Translation';

export type CriterionExample = {
    id: string;
    code: Translation;
    language: CriterionExample.language;
    description?: Translation;
}

export namespace CriterionExample {

    export enum language {
        HTMLBARS = 'htmlbars',
        SCSS = 'scss',
        CSS = 'css',
        JAVASCRIPT = 'javascript',
    }


}