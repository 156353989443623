import { ArticleCard } from '../../components/article-card/article-card';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useTranslation } from '../../hooks/useTranslation';
import { ArticleCardLabelType } from '../../shared';

import articleMargins from '../../assets/content-covers/article/article-margins.jpg';
import articleHeadings from '../../assets/content-covers/article/article-headings.jpg';
import articleAlt from '../../assets/content-covers/article/article-alt.jpg';
import articleImages from '../../assets/content-covers/article/article-images.jpg';
import articleFormats from '../../assets/content-covers/article/article-formates.jpg';
import articleProtocols from '../../assets/content-covers/article/article-protocols.jpg';
import articleInputs from '../../assets/content-covers/article/article-inputs.jpg';
import articleCms from '../../assets/content-covers/article/article-cms.jpg';
import articleId from '../../assets/content-covers/article/article-id.jpg';
import articleScss from '../../assets/content-covers/article/article-scss.jpg';

import './articles.page.scss';

export const ArticlesPage = () => {
  const { renderTranslation } = useTranslation();
  useDocumentTitle(renderTranslation({ ru: `Статьи`, uk: 'Статті' }));

  return (
    <main className='page articles-page'>
      <div className='container'>
        <section className='box'>
          <div className='page__head'>
            <h1 className='main-title main-title--indent'>
              {renderTranslation({ ru: `Статьи`, uk: 'Статті' })}
            </h1>
            <p className='main-title main-title--md'>
              {renderTranslation({
                ru: `Здесь собраны ссылки на полезные статьи`,
                uk: 'Тут зібрані посилання на корисні статті',
              })}
            </p>
          </div>

          <div className='articles-page__content'>
            <ul className='articles-list'>
              {articles.map((article) => (
                <li className='articles-list__item' key={article.cardLabel + article.cardLink}>
                  <ArticleCard
                    cardName={renderTranslation(article.cardName)}
                    cardImage={article.cardImage}
                    cardLink={article.cardLink}
                    cardLabel={article.cardLabel as ArticleCardLabelType}
                  />
                </li>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </main>
  );
};

const articles = [
  {
    cardName: {
      ru: 'Атрибут alt тега img',
      uk: 'Атрибут alt тега img',
    },
    cardImage: articleAlt,
    cardLink: 'https://f0t1.notion.site/alt-img-86699309899f44bfaaa275589260fb74',
    cardLabel: 'article',
  },
  {
    cardName: {
      ru: 'Форматы изображений для веба',
      uk: 'Формати зображень для вебу',
    },
    cardImage: articleFormats,
    cardLink: 'https://f0t1.notion.site/b970b4a8af8d4e8f9052fa01204d7acb',
    cardLabel: 'article',
  },
  {
    cardName: {
      ru: 'Организация заголовков в разметке HTML',
      uk: 'Організація заголовків в розмітці HTML',
    },
    cardImage: articleHeadings,
    cardLink: 'https://f0t1.notion.site/HTML-a80a484a99e0409b92ec1d6fa539dd46',
    cardLabel: 'article',
  },
  {
    cardName: {
      ru: 'Организация отступов в вёрстке',
      uk: 'Організація відступів у верстці',
    },
    cardImage: articleMargins,
    cardLink: 'https://f0t1.notion.site/d756a69ff46f497b8d0db181a40c4e27',
    cardLabel: 'article',
  },
  {
    cardName: {
      ru: 'Контентные и декоративные изображения, в чем разница?',
      uk: 'Контентні і декоративні зображення, у чому різниця?',
    },
    cardImage: articleImages,
    cardLink: 'https://f0t1.notion.site/704646d378ea4ddcb0c4000ad9abe7dc',
    cardLabel: 'article',
  },

  {
    cardName: {
      ru: 'Типы протоколов для ссылок',
      uk: 'Типи протоколів для посилань',
    },
    cardImage: articleProtocols,
    cardLink: 'https://f0t1.notion.site/eac35031787c4b31bcec2bf7346082aa',
    cardLabel: 'article',
  },

  {
    cardName: {
      ru: 'Список типов полей input',
      uk: 'Список типів полів input',
    },
    cardImage: articleInputs,
    cardLink: 'https://f0t1.notion.site/input-3ace93b38e8b4b4c9b2e15a13d34e309',
    cardLabel: 'article',
  },

  {
    cardName: { ru: 'Вёрстка для CMS', uk: 'Верстка для CMS' },
    cardImage: articleCms,
    cardLink: 'https://f0t1.notion.site/CMS-e37df656f4c24222bcca11fb4ed07baa',
    cardLabel: 'article',
  },

  {
    cardName: {
      ru: 'Использование атрибута id',
      uk: 'Використання атрибута id',
    },
    cardImage: articleId,
    cardLink: 'https://f0t1.notion.site/id-c68ad4c324de4265a3cfc43df8159214',
    cardLabel: 'article',
  },

  {
    cardName: {
      ru: 'Оформление кода SCSS + БЭМ',
      uk: 'Оформлення коду SCSS + БЕМ',
    },
    cardImage: articleScss,
    cardLink: 'https://f0t1.notion.site/SCSS-0a399503d5c24112acc55a24c7f55808',
    cardLabel: 'article',
  },
];
