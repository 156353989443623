import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { NavigationRoutes } from '../../../shared';
import { useHistory } from '../../../hooks/useHistory';

type Props = {
  children?: JSX.Element;
};

export const RequireAuth = ({ children }: Props) => {
  const { location } = useHistory();
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to={NavigationRoutes.login} state={{ from: location }} replace />;
  }

  return children ? children : <Outlet />;
};
