import { RegisterFeature } from '../../features/register/register.feature';
import { FormNav } from '../../components/forms/form-nav';
import { useTranslation } from '../../hooks/useTranslation';

export const RegisterPage = () => {
  const { renderTranslation } = useTranslation();

  return (
    <div className='page login-page'>
      <div className='container'>
        <div className='login-page__inner'>
          <div className='box login-page__form'>
            <FormNav />
            <RegisterFeature />
            <a
              className='login-page__link'
              href='https://t.me/mentors_help_admin'
              target='_blank'
              rel='noreferrer'>
              {renderTranslation({
                ru: 'Проблемы со входом / регистрацией?',
                uk: 'Проблеми зі входом/реєстрацією?',
              })}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
