import { CriteriaHead } from '../../components/criteria/criteria-head/criteria-head';
import { CriteriaFeature } from '../../features/criteria/criteria.feature';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useTranslation } from '../../hooks/useTranslation';
import { ProjectNavFeature } from '../../features/projects/projects-nav/project-nav.feature';

import './criteria-page.scss';

export const CriteriaPage = () => {
  const { renderTranslation } = useTranslation();

  useDocumentTitle(renderTranslation({ ru: `Критерии верстки`, uk: 'Критерії верстки' }));

  return (
    <div className='page wrapper wrapper--changeable'>
      <main className='criteria-page'>
        <CriteriaHead />
        <div className='criteria-page__inner'>
          <div className='container container--sm'>
            <CriteriaFeature />
          </div>
        </div>
      </main>
      <ProjectNavFeature />
    </div>
  );
};
