import { ProjectCard } from '../../components/project-card/project-card';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useTranslation } from '../../hooks/useTranslation';
import { useUserProjects } from '../../hooks/projects/useUserProjects';

import './projects.page.scss';

export const ProjectsPage = () => {
  const { renderTranslation } = useTranslation();
  useDocumentTitle(renderTranslation({ ru: 'Ваши проекти', uk: 'Ваші проекти' }));

  // Mentors/admins can have more projects from the BE than in the currentUser object
  const { projects: projectDtos } = useUserProjects();

  return (
    <main className='page projects-page'>
      <div className='container'>
        <section className='box'>
          <div className='page__head'>
            <h1 className='main-title main-title--indent'>
              {renderTranslation({ ru: 'Ваши проекти', uk: 'Ваші проекти' })}
            </h1>
          </div>

          <ul className='projects-list d-flex'>
            {projectDtos.map((project) => (
              <li className='projects-list__item' key={project.id}>
                <ProjectCard project={project} />
              </li>
            ))}
          </ul>
        </section>
      </div>
    </main>
  );
};
