import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { NavigationRoutes } from '../../../shared';
import { useHistory } from '../../../hooks/useHistory';

type Props = {
  children: JSX.Element;
};

export const LoggedInRedirect = ({ children }: Props) => {
  const { location } = useHistory();
  const { isLoggedIn } = useAuth();

  const prevUrl = location.state ? location.state.from : null;

  if (isLoggedIn) {
    return <Navigate to={prevUrl ? prevUrl : NavigationRoutes.home} replace />;
  }

  return children;
};
