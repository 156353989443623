/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { StudyClassDto } from './StudyClassDto';

export type CreateUserDto = {
    fullName: string;
    email: string;
    role: CreateUserDto.role;
    password: string;
    mentorEmail?: string;
    studyClassNumber?: number;
    /**
     * Project ids
     */
    projects?: Array<string>;
    registrationConfirmed?: boolean;
    availableStudyClasses?: Array<StudyClassDto>;
}

export namespace CreateUserDto {

    export enum role {
        ADMIN = 'admin',
        STUDENT = 'student',
        MENTOR = 'mentor',
    }


}