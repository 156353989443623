import React from 'react';
import { Modal } from '@mantine/core';
import { useTranslation } from '../../hooks/useTranslation';

import './alert-modal.scss';

type Props = {
  onModalClose: () => void;
  onModalSubmit: () => void;
  show: boolean;
  children: JSX.Element;
  controls?: {
    submitBtn: boolean;
    cancelBtn: boolean;
  };
  title: string;
};

export const AlertModal = ({
  onModalClose,
  onModalSubmit,
  show,
  children,
  controls,
  title,
}: Props) => {
  const { renderTranslation } = useTranslation();

  const formLayout = (
    <form className='box alert' onSubmit={onModalSubmit}>
      <h1 className='main-title main-title--md main-title--indent alert__title'>{title}</h1>
      <div className='alert__content'>{children}</div>
      {controls && (
        <div className='alert__foot'>
          {controls.submitBtn && (
            <button className='btn btn--green alert__btn'>
              {renderTranslation({ ru: 'Подтвердить', uk: 'Підтвердити' })}
            </button>
          )}
          {controls.cancelBtn && (
            <button className='btn ' type='button' onClick={onModalClose}>
              {renderTranslation({ ru: 'Отмена', uk: 'Скасувати' })}
            </button>
          )}
        </div>
      )}
    </form>
  );

  return (
    <Modal
      className={'alert-wrapper'}
      opened={show}
      onClose={onModalClose}
      withCloseButton={false}
      centered>
      {formLayout}
    </Modal>
  );
};
