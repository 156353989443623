import { useState } from 'react';

type Props = {
  id: string;
  text: string;
  disabledAttr?: boolean;
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
};

export const PasswordInput = ({ id, text, disabledAttr, placeholder, value, onChange }: Props) => {
  const [showPass, setShowPass] = useState(false);

  const showPassHandler = () => {
    setShowPass(!showPass);
  };

  return (
    <>
      <label htmlFor={id} className='main-form__label'>
        {text}
      </label>

      <div className='main-form__field'>
        <button
          className={`btn-eye ${showPass ? 'open' : ''}`}
          onClick={showPassHandler}
          type='button'></button>
        <input
          id={id}
          type={`${showPass ? 'text' : 'password'}`}
          className='main-form__input'
          placeholder={placeholder}
          disabled={disabledAttr}
          maxLength={18}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
    </>
  );
};
