/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RequirementCriteriaDto } from './RequirementCriteriaDto';

export type RequirementDto = {
    lessonNumber: number;
    criteriaIds: Array<RequirementCriteriaDto>;
    taskDescriptions: string;
    markupDescriptions: string;
    hints?: string;
    additionalTasks?: string;
    figmaTemplateTitle: RequirementDto.figmaTemplateTitle;
}

export namespace RequirementDto {

    export enum figmaTemplateTitle {
        FRESHOK = 'freshok',
        PORTFOLIO = 'portfolio',
        SIMPLE_FOOD = 'simple food',
    }


}