import { CriterionDto } from '../../../openapi';

export type CriteriaDataState = {
  commonCriteria: CriterionDto[];
  htmlCriteria: CriterionDto[];
  stylesCriteria: CriterionDto[];
  responsiveCriteria: CriterionDto[];
};

export const emptyCriteriaDataState: CriteriaDataState = {
  commonCriteria: [],
  htmlCriteria: [],
  stylesCriteria: [],
  responsiveCriteria: [],
};

export type State = {
  highlightedCriterionId: string | null;
  showDoneCriteria: boolean;
  // criteria for selected task only
  showRelevantCriteria: boolean;
};
