export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type EndpointsSchema = { method: HTTPMethod; path: string };

export const ApiEntitiesTags = {
  criteria: 'criteria',
  studyClass: 'studyClass',
  user: 'user',
};

export function createEndpoint(path: string) {
  return `/api/${path}`;
}
