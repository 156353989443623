/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SocialMediaDto } from './SocialMediaDto';

export type UpdateProfileDto = {
    fullName?: string;
    password?: string;
    phoneNumber?: string;
    country?: string;
    gender?: UpdateProfileDto.gender;
    socialMedia?: Array<SocialMediaDto>;
    dateOfBirth?: string;
    avatarUrl?: string;
}

export namespace UpdateProfileDto {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}