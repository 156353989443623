import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { envs, ApiEntitiesTags } from '../../shared';
import { RootState } from '../../store';

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envs.serverUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', `Bearer ${(getState() as RootState).user.token}`);

      return headers;
    },
  }),
  tagTypes: Object.values(ApiEntitiesTags),
  endpoints: () => ({}),
});
