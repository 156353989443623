import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { formatToDateString, Language, Languages } from '../../../shared';
import { MentorSummary, RegisterDto, StudyClassDto, UserDto } from '../../../openapi';
import { AuthErrorType, ErrorInfo } from '../form-error';
import { Spinner } from '../../spinner/Spinner';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetAllStudyClassesQuery } from '../../../features/study-class/redux/study-class-api';
import { useGetMentorsSummaryQuery } from '../../../features/user/redux/user-api';

import './../main-form.scss';

type Props = {
  onSubmit: (registerUserData: RegisterDto) => Promise<UserDto | undefined>;
  isLoading: boolean;
  isValid: boolean;
  errorType: AuthErrorType;
  resetErrorInfo: () => void;
};

export const RegisterForm = ({
  isValid,
  errorType,
  resetErrorInfo,
  isLoading,
  onSubmit,
}: Props) => {
  const { renderTranslation, currentLanguage } = useTranslation();
  const { data: allMentors = [], isFetching: mentorsLoading } = useGetMentorsSummaryQuery();
  const { data: studyClasses = [], isFetching: studyClassLoading } = useGetAllStudyClassesQuery();

  const [canSelectMentor, setCanSelectMentor] = useState(true);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mentorEmail, setMentorEmail] = useState<MentorEmailState>();
  const [studyClassNumber, setStudyClassNumber] = useState<StudyClassState>();
  const [mentors, setMentors] = useState(
    studyClassNumber ? filterMentorsByStudyClass(allMentors, studyClassNumber.value) : [],
  );

  useEffect(() => {
    setCanSelectMentor(mentors.length > 0);
  }, [mentors]);

  const onFullNameChange = (value: string) => setFullName(value);

  const onEmailChange = (value: string) => setEmail(value);

  const onPasswordChange = (value: string) => setPassword(value);

  const onStudyClassChange = (newValue: SingleValue<StudyClassState>) => {
    setStudyClassNumber(newValue!);
    setMentors(filterMentorsByStudyClass(allMentors, newValue!.value));
  };

  const onMentorEmailChange = (newValue: SingleValue<MentorEmailState>) => {
    setMentorEmail(newValue!);
  };

  const resetForm = () => {
    setFullName('');
    setEmail('');
    setPassword('');
    setMentorEmail(undefined);
    setStudyClassNumber(undefined);
  };

  const onSubmitPress = async (event: React.FormEvent) => {
    event.preventDefault();

    if ((canSelectMentor && !mentorEmail) || !studyClassNumber) {
      alert(
        renderTranslation({
          ru: 'Необходимо указать email ментора и номер потока',
          uk: 'Необхідно вказати email ментора та номер потоку',
        }),
      );
      return;
    }

    const submitResult = await onSubmit({
      email,
      ...(canSelectMentor && mentorEmail && { mentorEmail: mentorEmail.value }),
      fullName,
      studyClassNumber: studyClassNumber.value,
      password,
    });

    if (submitResult) {
      resetForm();
    }
  };

  return (
    <form className='main-form' onSubmit={onSubmitPress}>
      <div className='main-form__group'>
        <label className='main-form__label' htmlFor='fullName'>
          {renderTranslation({ ru: 'Имя Фамилия', uk: 'Ім`я прізвище' })}
        </label>
        <input
          className='main-form__input'
          id='fullName'
          placeholder={renderTranslation({
            ru: 'Введите ваши Имя и Фамилию',
            uk: "Введіть ваші Ім'я та Прізвище",
          })}
          type='text'
          value={fullName}
          onChange={({ target }) => onFullNameChange(target.value)}
          required
        />
      </div>

      <div className={`main-form__group ${!isValid ? 'main-form__group-error' : ''}`}>
        <label className='main-form__label' htmlFor='email'>
          Ваш email
        </label>
        <input
          className='main-form__input'
          id='email'
          placeholder={renderTranslation({ ru: 'Введите email', uk: 'Введіть email' })}
          onFocus={resetErrorInfo}
          type='email'
          value={email}
          onChange={({ target }) => onEmailChange(target.value)}
          required
        />
      </div>

      <div className={`main-form__group ${!isValid ? 'main-form__group-error' : ''}`}>
        <label className='main-form__label' htmlFor='password'>
          Пароль
        </label>
        <input
          className='main-form__input'
          id='password'
          placeholder={renderTranslation({ ru: 'Введите пароль', uk: 'Введіть пароль' })}
          minLength={6}
          type='password'
          value={password}
          onChange={({ target }) => onPasswordChange(target.value)}
          required
        />
      </div>

      <div className={`main-form__group`}>
        <label className='main-form__label' htmlFor='classNum'>
          {renderTranslation({ ru: 'Поток', uk: 'Потік' })}
        </label>
        <Select
          className='main-form__select basic-single'
          classNamePrefix='select'
          styles={selectStyles}
          options={mapStudyClassesToSelectArray(studyClasses, currentLanguage)}
          value={studyClassNumber}
          onChange={onStudyClassChange}
          name={renderTranslation({ ru: 'Поток', uk: 'Потік' })}
          placeholder={renderTranslation({ ru: 'Выберите поток', uk: 'Виберіть потік' })}
          isLoading={studyClassLoading}
          id='classNum'
        />
      </div>

      <div className={`main-form__group`}>
        <label className='main-form__label' htmlFor='yourMentor'>
          Ментор
        </label>
        <Select
          className='main-form__select basic-single'
          classNamePrefix='select'
          options={mapMentorsToSelectArray(mentors)}
          value={mentorEmail}
          styles={selectStyles}
          onChange={onMentorEmailChange}
          name='Ментор'
          placeholder={renderTranslation({ ru: 'Выберите наставника', uk: 'Виберіть наставника' })}
          isLoading={mentorsLoading}
          isDisabled={!canSelectMentor}
        />
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <button className='btn btn--green btn--lg' type='submit'>
          {renderTranslation({ ru: 'Зарегистрироваться', uk: 'Зареєструватись' })}
        </button>
      )}

      <ErrorInfo type={errorType} show={!isValid} />
    </form>
  );
};

type StudyClassState = { value: number; label: string };

type MentorEmailState = { value: string; label: string };

function mapStudyClassesToSelectArray(studyClasses: StudyClassDto[], language: Language) {
  return [...studyClasses].reverse().map((studyClass) => {
    const translateLabel = (translation: string) =>
      `${translation} №${studyClass.classNumber}: ${formatToDateString(
        studyClass.startDate,
      )} - ${formatToDateString(studyClass.endDate)}`;

    return {
      value: studyClass.classNumber,
      label: translateLabel(language === Languages.uk ? 'Потік' : 'Поток'),
    };
  });
}

function mapMentorsToSelectArray(mentors: MentorSummary[]) {
  return mentors.map((summary) => ({ value: summary.email, label: summary.fullName }));
}

function filterMentorsByStudyClass(
  mentors: MentorSummary[],
  selectedStudyClassNumber: number,
): MentorSummary[] {
  return mentors.filter(
    (mentor) =>
      mentor.availableStudyClasses &&
      mentor.availableStudyClasses
        .map((studyClass) => studyClass.classNumber)
        .includes(selectedStudyClassNumber),
  );
}

const selectStyles = {
  control: () => ({}),
  placeholder: () => ({}),
  valueContainer: () => ({}),
  indicatorsContainer: () => ({}),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({}),
  dropdownIndicator: () => ({
    height: '20px',
    width: '20px',
    color: 'rgba(var(--c-rgb-violet-200), 0.4)',
  }),
  input: () => ({}),
};
