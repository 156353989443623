export const NavigationRoutes = {
  home: '/',
  criteria: '/criteria',
  login: '/login',
  register: '/signup',
  profile: '/profile',
  studentPage: '/student-page',
  projects: '/projects',
  about: '/about',
  cooperation: '/cooperation',
  tutorials: '/tutorials',
  articles: '/articles',
  consult: '/consult',
  tasks: '/tasks',
  donate: '/donate',
  error: '/404',
};
