import { Button } from '@mantine/core';
import { CriteriaSettings } from '../criteria-settings/criteria-settings';
import { useTranslation } from '../../../hooks/useTranslation';
import { useCurrentUser } from '../../../hooks/redux/useCurrentUser';
import { useCurrentProjectNav } from '../../../hooks/projects/useCurrentProjectNav';
import { useResetCriteriaProgressMutation } from '../../../features/user/redux/user-api';

import './criteria-head.scss';
import './faq.scss';

export const CriteriaHead = () => {
  const { renderTranslation } = useTranslation();
  const { isAdminRole, isMentorRole } = useCurrentUser();
  const { currentProject } = useCurrentProjectNav();
  const [resetProgress, { isLoading }] = useResetCriteriaProgressMutation();

  const onResetProgressBtnClick = () => {
    resetProgress({ projectId: currentProject.id });
  };

  return (
    <section className='criteria-head'>
      <div className='container container--sm'>
        <div className='criteria-head__inner box'>
          <h1 className='main-title main-title--indent'>
            {renderTranslation({ ru: 'Критерии вёрстки', uk: 'Критерії верстки' })}
          </h1>
          <div className='criteria-head__group faq'>
            <h2 className='main-title main-title--md main-title--indent'>FAQ </h2>

            <details className='faq__item'>
              <summary className='faq__question'>
                {renderTranslation({
                  ru: 'Что такое критерии вёрстки?',
                  uk: 'Що таке критерії верстки?',
                })}
              </summary>
              <div className='faq__answer'>
                <p>
                  {renderTranslation({
                    ru:
                      'Критерии - это собранные в одном месте стандарты вёрстки. Работая по критериям, мы учимся писать грамотный код, соответствующий требованиям работодателя.',
                    uk:
                      'Критерії - це зібрані в одному місці стандарти верстки. Працюючи за критеріями, ми вчимося писати грамотний код, який відповідає вимогам роботодавця.',
                  })}
                </p>
              </div>
            </details>

            <details className='faq__item'>
              <summary className='faq__question'>
                {renderTranslation({ ru: 'Правила работы с ДЗ', uk: 'Правила роботи з ДЗ' })}
              </summary>
              <div className='faq__answer'>
                <ol>
                  <li>
                    {renderTranslation({
                      ru:
                        'Задания выполняются поэтапно. То есть делаем сначала то, что требуется, отправляем на проверку, ждем проверку, вносим правки и только потом делаем следующую часть. Не торопимся наделать ошибок наперёд, потому что исправлять их будет сложнее, более того будет теряться куча времени. Если нужна помощь во время выполнения задания – пишем наставнику в телеграм, задаем вопросы, не стесняемся)',
                      uk:
                        'Завдання виконуються поетапно. Тобто робимо спочатку те, що потрібно, відправляємо на перевірку, чекаємо на перевірку, вносимо правки і тільки потім робимо наступну частину. Не поспішаємо наробити помилок наперед, тому що виправляти їх буде складніше, більш того - втрачатиметься купа часу. Якщо потрібна допомога під час виконання завдання – пишемо наставнику в телеграм, ставимо запитання, не соромимося)',
                    })}
                  </li>
                  <li>
                    {renderTranslation({
                      ru:
                        'Переходим к выполнению следующего занятия только после внесения правок по предыдущему и т.п.',
                      uk:
                        'Переходимо до виконання наступного заняття лише після внесення поправок за попереднім і т.п.',
                    })}
                  </li>
                  <li>
                    {renderTranslation({
                      ru:
                        'Если задание не соответствует требованиям и критериям, то наставник может отправить задание на доработку, так что стараемся внимательно вчитываться в критерии и требования, и придерживаться их.',
                      uk:
                        'Якщо завдання не відповідає вимогам та критеріям, то наставник може відправити завдання на доопрацювання, так що намагаємося уважно вчитуватися в критерії та вимоги, та дотримуватись їх.',
                    })}
                  </li>
                  <li>
                    {renderTranslation({
                      ru:
                        'Перед отправкой нужно убедиться, что все необходимые критерии выполнены. Спешка не нужна, главное в данном случае не скорость, а качество.',
                      uk:
                        'Перед відправкою слід переконатися, що всі необхідні критерії виконані. Поспіх не потрібний, головне в даному випадку не швидкість, а якість.',
                    })}
                  </li>
                  <li>
                    {renderTranslation({
                      ru:
                        'Если возникают вопросы по критериям или требованиям - лучше уточнить у наставника; Если заметили ошибку или неточность - пишем наставнику.',
                      uk:
                        'Якщо виникають питання щодо критеріїв чи вимог – краще уточнити у наставника; Якщо помітили помилку чи неточність – пишемо наставнику.',
                    })}
                  </li>
                </ol>
              </div>
            </details>
          </div>
          <div className='criteria-head__group'>
            <h2 className='main-title main-title--md main-title--indent'>
              {renderTranslation({
                ru: 'Настройки показа критериев',
                uk: 'Налаштування показу критеріїв',
              })}
            </h2>
            <CriteriaSettings />

            {(isAdminRole || isMentorRole) && (
              <Button
                className='criteria-head__reset-btn btn '
                loading={isLoading}
                onClick={onResetProgressBtnClick}>
                {renderTranslation({
                  ru: 'Сбросить выполненные критерии',
                  uk: 'Скинути виконані критерії',
                })}
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
