export function getRequiredEnv(key: string) {
  const envVar = process.env[key];

  if (typeof envVar === 'undefined' || envVar === '') {
    throw new Error(`Env variable ${key} not found`);
  }

  return envVar;
}

export const envs = {
  serverUrl: getRequiredEnv('REACT_APP_SERVER_URL'),
};
