import { ProgressBar } from '../../progress-bar/progress-bar';
import { useCurrentProjectNav } from '../../../hooks/projects/useCurrentProjectNav';
import { lessonStatusIsDone } from '../../../shared';
import { useCurrentUser } from '../../../hooks/redux/useCurrentUser';

export const ProjectProgressBar = () => {
  const { currentLesson } = useCurrentProjectNav();
  const { currentUserProject } = useCurrentUser();
  const checkedCriteriaCount = currentUserProject.criteriaProgress.filter((criteriaProgress) =>
    lessonStatusIsDone(criteriaProgress.status),
  ).length;

  const criteriaCount = currentLesson.criteria.length;

  const checkedPercentage = ((checkedCriteriaCount / criteriaCount) * 100).toFixed();

  return <ProgressBar color='green' percentage={checkedPercentage} />;
};
