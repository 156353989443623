import { ProgressBar } from '../progress-bar/progress-bar';
import { useCurrentUser } from '../../hooks/redux/useCurrentUser';
import { useTranslation } from '../../hooks/useTranslation';
import { CriterionDto, DesignAssetsEntity, ProjectDto } from '../../openapi';

import './project-card.scss';

type Props = {
  project: ProjectDto;
};

export const ProjectCard = ({ project }: Props) => {
  const { renderTranslation } = useTranslation();
  const { normalizedUserProjects } = useCurrentUser();
  const usersProject = normalizedUserProjects[project.id];
  const totalProjectCriteria = project.lessons.reduce((result, lesson) => {
    const filteredCriteriaInLesson = lesson.criteria.filter(
      (criterion) => !result.find((resultCriterion) => resultCriterion.id === criterion.id),
    );
    result.push(...filteredCriteriaInLesson);
    return result;
  }, [] as CriterionDto[]);

  const totalCriteriaCount = totalProjectCriteria.length;
  const checkedCriteriaCount = usersProject.criteriaProgress.length;
  const projectProgressPercentage = Number(
    ((checkedCriteriaCount / totalCriteriaCount) * 100).toFixed(),
  );
  const projectDesignEntity = project.designAssets.find(
    (design) => design.type === DesignAssetsEntity.type.FIGMA,
  );

  return (
    <article className='project-card'>
      <div className='project-card__content d-flex'>
        <figure className='project-card__image'>
          <img
            src={project.projectImageUrl}
            alt={renderTranslation({
              ru: `Превью проекта ${renderTranslation(project.name)}}`,
              uk: `Прев'ю проєкту ${renderTranslation(project.name)}`,
            })}
          />
          <a
            className='project-card__link'
            href={projectDesignEntity?.link ?? ''}
            target='_blank'
            title={renderTranslation({
              ru: 'Открыть макет в новой вкладке',
              uk: 'Відкрити макет у новій вкладці',
            })}
          />
        </figure>
        <div className='project-card__info'>
          <h2 className='project-card__name'>{renderTranslation(project.name)}</h2>
          <div className='project-card__stats'>
            <p>
              Всего критериев: <span>{totalCriteriaCount}</span>
            </p>
            <p>
              Выполнено критериев: <span>{checkedCriteriaCount}</span>
            </p>
          </div>
        </div>
      </div>
      <div className='project-card__progress'>Прогресс</div>
      <ProgressBar percentage={projectProgressPercentage} color={project.color} />
    </article>
  );
};
