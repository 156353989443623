import { ProjectsNav } from '../../../components/projects/projects-nav/projects-nav';
import { ProjectLessonsNav } from '../../../components/projects/project-lessons-nav';
import { ProjectProgressBar } from '../../../components/projects/project-progress-bar';
import { ProjectNavCriteriaList } from '../../../components/projects/tasks-list/project-nav-criteria-list';
import { useTranslation } from '../../../hooks/useTranslation';
import { ProjectLessonDetails } from '../../../components/projects/project-lesson-details/project-lesson-details';

import '../../../components/projects/criteria-nav.scss';
import '../../../components/projects/requirements.scss';

export const ProjectNavFeature = () => {
  const { renderTranslation } = useTranslation();

  return (
    <div className='criteria-helper'>
      <section className='criteria-nav'>
        <div className='criteria-nav__inner'>
          <h2 className='sr-only'>
            {renderTranslation({ ru: 'Навигация по критериям', uk: 'Навігація по критеріям' })}
          </h2>

          <ProjectsNav />
          <ProjectLessonsNav />

          <div className='criteria-nav__group project-progress'>
            <h3 className='criteria-nav__title text-center'>
              {renderTranslation({ ru: 'Прогресс', uk: 'Прогрес' })}
            </h3>
            <ProjectProgressBar />
          </div>

          <ProjectNavCriteriaList />
        </div>
      </section>

      <ProjectLessonDetails />
    </div>
  );
};
