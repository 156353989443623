import { Middleware } from 'redux';
import { MiddlewareAPI } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { setSelectedLessonNumberToStorage, setSelectedProjectIdToStorage } from '../../../shared';
import { ProjectDto } from '../../../openapi';
import { selectLessonNumber, selectProject } from './projects-slice';

export const projectsLocalStorageMiddleWare: Middleware<{}, RootState> = () => (next) => (
  action,
) => {
  if (action.type === selectProject.type && typeof action.payload === 'string') {
    setSelectedProjectIdToStorage(action.payload);
  }

  if (action.type === selectLessonNumber.type && typeof action.payload === 'string') {
    setSelectedLessonNumberToStorage(action.payload);
  }

  return next(action);
};

export const selectProjectOnAppInitMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (
  action,
) => {
  if (
    !(
      action.meta?.arg?.endpointName === 'getUsersProjects' &&
      action.meta.requestStatus === 'fulfilled'
    )
  ) {
    return next(action);
  }

  if (
    action.meta?.arg?.endpointName === 'getUsersProjects' &&
    action.meta.requestStatus === 'fulfilled' &&
    typeof action.payload === 'object' &&
    Array.isArray(action.payload)
  ) {
    const selectedProjectIdInState = (api.getState() as RootState).projects.selectedProjectId;

    const correspondingProjectDto = (action.payload as ProjectDto[]).find(
      (project) => project.id === selectedProjectIdInState,
    );

    if (!correspondingProjectDto) {
      // Projects array is already sorted on the BE, and we assume that user always has at least 1 project
      api.dispatch(selectProject((action.payload[0] as ProjectDto).id));
    }
  }

  return next(action);
};
