import { useState } from 'react';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import { CriteriaProgressItemDto, CriterionDto } from '../../../openapi';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useTranslation } from '../../../hooks/useTranslation';
import { AlertModal } from '../../modal';
import { useCurrentUser } from '../../../hooks/redux/useCurrentUser';
import { useCurrentProjectNav } from '../../../hooks/projects/useCurrentProjectNav';
import { lessonStatusIsDone } from '../../../shared';
import { useUpdateCriteriaProgressMutation } from '../../../features/user/redux/user-api';
import { CardAccordion } from './criteria-card-accordion';
import { ConfirmModal } from './confirm-modal';
import { Checkbox } from './criteria-card-checkbox';

import './criteria-card.scss';

const { TODO } = CriteriaProgressItemDto.status;

type Props = {
  criterion: CriterionDto;
};

export const CriteriaCard = ({
  criterion: { id, category, details, examples, description, number },
}: Props) => {
  const { renderTranslation } = useTranslation();
  const { currentLesson } = useCurrentProjectNav();
  const { isStudentRole, normalizedUserCriteriaProgress, currentUserProject } = useCurrentUser();
  const [updateCriteriaProgress, { isLoading }] = useUpdateCriteriaProgressMutation();
  const [showAlert, setShowAlert] = useState(false);

  const highlightedCriterionId = useAppSelector(
    (state) => state.criteria.highlightedCriterionId ?? null,
  );
  const criterionProgress = normalizedUserCriteriaProgress[id];
  const isRequiredInSelectedLesson = currentLesson.criteria.find(
    (criterion) => criterion.id === id,
  );

  const isDone = lessonStatusIsDone(criterionProgress?.status);
  const transformedCategory = category[0];
  const transformedNumber = `${transformedCategory}-${number}`;

  const onCheckboxChangeHandler = (status: CriteriaProgressItemDto.status) => {
    if (isStudentRole && !lessonStatusIsDone(status) && !showAlert) {
      setShowAlert(true);
      return;
    }

    updateCriteriaProgress({
      projectId: currentUserProject.projectId,
      criterionId: id,
      status,
    });
  };

  const hideAlert = () => {
    setShowAlert(false);
  };

  const onConfirmAlertSubmitHandler = () => {
    onCheckboxChangeHandler(TODO);
    hideAlert();
  };

  const criteriaCardClasses = `
    criteria-card ${highlightedCriterionId === id ? 'highlight' : ''}
    ${isRequiredInSelectedLesson ? '' : 'disabled'}
  `;

  return (
    <article className={criteriaCardClasses} id={id}>
      <div className='criteria-card__main'>
        <div className='criteria-card__desc'>
          <span className='criteria-card__id'>{transformedNumber}</span>
          {convertHtmlToReact(renderTranslation(description), {})}
        </div>

        {details && (
          <CardAccordion
            buttonText={renderTranslation({ ru: 'Подробнее', uk: 'Детальніше' })}
            details={details}
          />
        )}
        {!!examples?.length && (
          <CardAccordion
            buttonText={renderTranslation({ ru: 'Пример', uk: 'Приклад' })}
            examples={examples}
          />
        )}

        <Checkbox value={isDone} onCheckboxChange={onCheckboxChangeHandler} isLoading={isLoading} />

        <AlertModal
          show={showAlert}
          onModalClose={hideAlert}
          onModalSubmit={onConfirmAlertSubmitHandler}
          controls={{ cancelBtn: true, submitBtn: true }}
          title={renderTranslation({ ru: 'Подтверждение', uk: 'Підтвердження' })}>
          <ConfirmModal />
        </AlertModal>
      </div>
    </article>
  );
};
