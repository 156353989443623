import { RequirementDto, UserDto } from '../openapi';
import { Language } from './shared.types';

export const FemaleGender = UserDto.gender.FEMALE;
export const MaleGender = UserDto.gender.MALE;

export const PortfolioProject = RequirementDto.figmaTemplateTitle.PORTFOLIO;
export const FreshokProject = RequirementDto.figmaTemplateTitle.FRESHOK;
export const SimpleFoodProject = RequirementDto.figmaTemplateTitle.SIMPLE_FOOD;
export const FigmaProjectsValues = [PortfolioProject, FreshokProject, SimpleFoodProject];

export const Languages: { ru: Language; uk: Language } = {
  ru: 'ru',
  uk: 'uk',
};
