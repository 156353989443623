/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateStudentTaskReviewDto } from '../models/CreateStudentTaskReviewDto';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { CriteriaProgressItemDto } from '../models/CriteriaProgressItemDto';
import type { CurrentUserDto } from '../models/CurrentUserDto';
import type { GetUsersResponseDto } from '../models/GetUsersResponseDto';
import type { LinkedUserDto } from '../models/LinkedUserDto';
import type { MentorSummary } from '../models/MentorSummary';
import type { PasswordDto } from '../models/PasswordDto';
import type { PasswordStatusDto } from '../models/PasswordStatusDto';
import type { UpdateProfileDto } from '../models/UpdateProfileDto';
import type { UpdateStudentsProjectsDto } from '../models/UpdateStudentsProjectsDto';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { UpdateUserProjectProgressBodyDto } from '../models/UpdateUserProjectProgressBodyDto';
import type { UserDto } from '../models/UserDto';
import type { UserPasswordDto } from '../models/UserPasswordDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UserApi {

    /**
     * @returns UserDto 
     * @throws ApiError
     */
    public static userControllerCreate({
requestBody,
}: {
requestBody: CreateUserDto,
}): CancelablePromise<UserDto> {
        return __request({
            method: 'POST',
            path: `/api/user`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetUsersResponseDto 
     * @throws ApiError
     */
    public static userControllerFindAll({
skip,
limit,
searchTerm,
role,
studyClass,
mentorEmail,
registrationConfirmed,
}: {
skip: number,
limit?: number,
searchTerm?: string,
role?: 'admin' | 'student' | 'mentor',
studyClass?: number,
mentorEmail?: string,
registrationConfirmed?: boolean,
}): CancelablePromise<GetUsersResponseDto> {
        return __request({
            method: 'GET',
            path: `/api/user`,
            query: {
                'skip': skip,
                'limit': limit,
                'searchTerm': searchTerm,
                'role': role,
                'studyClass': studyClass,
                'mentorEmail': mentorEmail,
                'registrationConfirmed': registrationConfirmed,
            },
        });
    }

    /**
     * @returns UserDto 
     * @throws ApiError
     */
    public static userControllerUpdateUser({
email,
requestBody,
}: {
email: string,
requestBody: UpdateUserDto,
}): CancelablePromise<UserDto> {
        return __request({
            method: 'PATCH',
            path: `/api/user`,
            query: {
                'email': email,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static userControllerDeleteUser({
email,
}: {
email: string,
}): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/user`,
            query: {
                'email': email,
            },
        });
    }

    /**
     * @returns PasswordStatusDto 
     * @throws ApiError
     */
    public static userControllerValidatePasswordChange({
requestBody,
}: {
requestBody: PasswordDto,
}): CancelablePromise<PasswordStatusDto> {
        return __request({
            method: 'POST',
            path: `/api/user/validate/password`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserDto 
     * @throws ApiError
     */
    public static userControllerFindOne({
email,
}: {
email: string,
}): CancelablePromise<UserDto> {
        return __request({
            method: 'GET',
            path: `/api/user/one`,
            query: {
                'email': email,
            },
        });
    }

    /**
     * @returns MentorSummary 
     * @throws ApiError
     */
    public static userControllerGetMentorsSummary(): CancelablePromise<Array<MentorSummary>> {
        return __request({
            method: 'GET',
            path: `/api/user/mentors-summary`,
        });
    }

    /**
     * @returns CurrentUserDto 
     * @throws ApiError
     */
    public static userControllerGetCurrentUser(): CancelablePromise<CurrentUserDto> {
        return __request({
            method: 'GET',
            path: `/api/user/current`,
        });
    }

    /**
     * @returns UserPasswordDto 
     * @throws ApiError
     */
    public static userControllerGetPassword(): CancelablePromise<UserPasswordDto> {
        return __request({
            method: 'GET',
            path: `/api/user/password`,
        });
    }

    /**
     * @returns LinkedUserDto 
     * @throws ApiError
     */
    public static userControllerGetLinkedUsers(): CancelablePromise<Array<LinkedUserDto>> {
        return __request({
            method: 'GET',
            path: `/api/user/linked-users`,
        });
    }

    /**
     * @returns CriteriaProgressItemDto 
     * @throws ApiError
     */
    public static userControllerUpdateUserCriteriaProgress({
criterionId,
projectId,
requestBody,
userEmail,
}: {
criterionId: string,
projectId: string,
requestBody: UpdateUserProjectProgressBodyDto,
userEmail?: string,
}): CancelablePromise<CriteriaProgressItemDto> {
        return __request({
            method: 'PATCH',
            path: `/api/user/progress`,
            query: {
                'criterionId': criterionId,
                'projectId': projectId,
                'userEmail': userEmail,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserDto 
     * @throws ApiError
     */
    public static userControllerResetUserCriteriaProgress({
projectId,
}: {
/** Project id **/
projectId: string,
}): CancelablePromise<UserDto> {
        return __request({
            method: 'PATCH',
            path: `/api/user/progress/reset`,
            query: {
                'projectId': projectId,
            },
        });
    }

    /**
     * @returns UserDto 
     * @throws ApiError
     */
    public static userControllerCreateOrUpdateTaskReview({
requestBody,
}: {
requestBody: CreateStudentTaskReviewDto,
}): CancelablePromise<UserDto> {
        return __request({
            method: 'PATCH',
            path: `/api/user/task-review`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserDto 
     * @throws ApiError
     */
    public static userControllerUpdateStudentProjects({
email,
requestBody,
}: {
email: string,
requestBody: UpdateStudentsProjectsDto,
}): CancelablePromise<UserDto> {
        return __request({
            method: 'PATCH',
            path: `/api/user/projects/${email}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserDto 
     * @throws ApiError
     */
    public static userControllerUpdateProfile({
requestBody,
}: {
requestBody: UpdateProfileDto,
}): CancelablePromise<UserDto> {
        return __request({
            method: 'PATCH',
            path: `/api/user/profile`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}