import { useAppSelector } from './useAppSelector';

type ReturnValue = {
  isLoggedIn: boolean;
  wasLoggedIn: boolean;
  token: string;
};

export const useAuth = (): ReturnValue => {
  const token = useAppSelector((state) => state.user.token);
  const wasLoggedIn = useAppSelector((state) => state.user.wasLoggedIn);
  const isLoggedIn = Boolean(token);

  // currentUser must be defined, thanks to useRequiredDataLoader hook
  return { isLoggedIn, token, wasLoggedIn };
};
