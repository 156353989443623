type AuthData = {
  token: string;
};

const storageKeys = {
  token: 'token',
  showRelevantCriteria: 'show-relevant-criteria',
  showDoneCriteria: 'show-done-criteria',
  selectedProjectId: 'selected-project-id',
  selectedLessonNumber: 'selected-lesson-number',
};

export function setAuthData(authData: AuthData) {
  localStorage.setItem(storageKeys.token, authData.token);
}

export function getAuthData(): AuthData | null {
  const token = localStorage.getItem(storageKeys.token);

  return token
    ? {
        token,
      }
    : null;
}

export function getShowRelevantCriteriaFromStorage(): boolean | null {
  const showRelevantCriteria = localStorage.getItem(storageKeys.showRelevantCriteria);

  if (!showRelevantCriteria) {
    return null;
  }

  return showRelevantCriteria === String(true);
}

export function getShowDoneCriteriaFromStorage(): boolean | null {
  const showDoneCriteria = localStorage.getItem(storageKeys.showDoneCriteria);

  if (!showDoneCriteria) {
    return null;
  }

  return showDoneCriteria === String(true);
}

export function setShowRelevantCriteriaToStorage(value: boolean) {
  const stringValue = String(value);

  localStorage.setItem(storageKeys.showRelevantCriteria, stringValue);
}
export function setShowDoneCriteriaToStorage(value: boolean) {
  const stringValue = String(value);

  localStorage.setItem(storageKeys.showDoneCriteria, stringValue);
}

export function getSelectedProjectIdFromStorage(): string | null {
  return localStorage.getItem(storageKeys.selectedProjectId);
}

export function setSelectedProjectIdToStorage(value: string) {
  localStorage.setItem(storageKeys.selectedProjectId, value);
}

export function getSelectedLessonNumberFromStorage(): number | null {
  const selectedLessonNumber = localStorage.getItem(storageKeys.selectedLessonNumber);

  if (!selectedLessonNumber) {
    return null;
  }

  return Number(selectedLessonNumber);
}

export function setSelectedLessonNumberToStorage(value: number) {
  const stringValue = String(value);

  localStorage.setItem(storageKeys.selectedLessonNumber, stringValue);
}
