/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserProjectProgressBodyDto = {
    status: UpdateUserProjectProgressBodyDto.status;
}

export namespace UpdateUserProjectProgressBodyDto {

    export enum status {
        TODO = 'todo',
        DONE = 'done',
        WARNING = 'warning',
    }


}