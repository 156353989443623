/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PasswordStatusDto = {
    password: string;
    status: PasswordStatusDto.status;
    errors?: PasswordStatusDto.errors;
}

export namespace PasswordStatusDto {

    export enum status {
        SUCCESS = 'success',
        ERROR = 'error',
    }

    export enum errors {
        LENGTH = 'length',
        CYRILLIC = 'cyrillic',
        DUPLICATE = 'duplicate',
    }


}