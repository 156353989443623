/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Translation } from './Translation';

export type DesignAssetsEntity = {
    type: DesignAssetsEntity.type;
    link: string;
    description: Translation;
}

export namespace DesignAssetsEntity {

    export enum type {
        FIGMA = 'figma',
        PHOTOSHOP = 'photoshop',
    }


}