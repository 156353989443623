import React, { useState } from 'react';
import { isEmptyString } from '../../../shared';
import { Spinner } from '../../spinner/Spinner';
import { AuthErrorType, ErrorInfo } from '../form-error';
import { useTranslation } from '../../../hooks/useTranslation';

import './../main-form.scss';

type Props = {
  onValidSubmit: (email: string, password: string) => void;
  isLoading: boolean;
  isValid: boolean;
  errorType: AuthErrorType;
  resetErrorInfo: () => void;
};

export const AuthForm = ({
  onValidSubmit,
  isLoading,
  isValid,
  errorType,
  resetErrorInfo,
}: Props) => {
  const { renderTranslation } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (isEmptyString(email)) {
      return;
    }

    onValidSubmit(email, password);
  }

  function resetErrorHandler() {
    if (!isValid) {
      resetErrorInfo();
    }
  }

  return (
    <>
      <form className='main-form' onSubmit={onSubmit}>
        {!isLoading ? (
          <>
            <div className='main-form__group'>
              <label htmlFor='login' className='main-form__label'>
                {renderTranslation({ ru: 'Ваш email', uk: 'Ваш email' })}
              </label>
              <input
                className={`main-form__input ${!isValid ? 'main-form--error' : ''}`}
                id='login'
                placeholder={renderTranslation({ ru: 'Введите email', uk: 'Введіть email' })}
                type='email'
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                onFocus={resetErrorHandler}
                required
              />
            </div>
            <div className='main-form__group'>
              <label htmlFor='password' className='main-form__label'>
                Пароль
              </label>
              <input
                className={`main-form__input ${!isValid ? 'main-form--error' : ''}`}
                id='password'
                placeholder={renderTranslation({ ru: 'Введите пароль', uk: 'Введіть пароль' })}
                type='password'
                minLength={6}
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                onFocus={resetErrorHandler}
                required
              />
            </div>
            <button className='btn btn--green btn--lg' type='submit'>
              {renderTranslation({ ru: 'Войти', uk: 'Увійти' })}
            </button>
            <ErrorInfo type={errorType} show={!isValid} />
          </>
        ) : (
          <Spinner />
        )}
      </form>
    </>
  );
};
