/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SocialMediaDto } from './SocialMediaDto';
import type { StudyClassDto } from './StudyClassDto';

export type UpdateUserDto = {
    id?: string;
    fullName?: string;
    email?: string;
    password?: string;
    role?: UpdateUserDto.role;
    mentorEmail?: string;
    studyClass?: StudyClassDto;
    createdAt?: string;
    banned?: boolean;
    registrationConfirmed?: boolean;
    lastDayAccess?: string;
    phoneNumber?: string;
    country?: string;
    availableStudyClasses?: Array<StudyClassDto>;
    gender?: UpdateUserDto.gender;
    finishedCourses?: number;
    socialMedia?: Array<SocialMediaDto>;
    dateOfBirth?: string;
    avatarUrl?: string;
}

export namespace UpdateUserDto {

    export enum role {
        ADMIN = 'admin',
        STUDENT = 'student',
        MENTOR = 'mentor',
    }

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}