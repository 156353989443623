import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAuthData } from '../../../shared';
import { State } from './types';

const initialState: State = {
  token: getAuthData()?.token ?? '',
  wasLoggedIn: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.wasLoggedIn = true;
    },
    logout: (state) => {
      state.token = '';
    },
  },
});

export const { setToken, logout } = userSlice.actions;

export default userSlice.reducer;
