import { CriterionDto } from '../openapi';
import { Language } from './shared.types';
import { Languages } from './shared.global-values';

export function isNotEmptyString(...strings: string[]): boolean {
  if (!strings.length) {
    return false;
  }

  return (
    strings.filter((str) => typeof str === 'string' && str.length > 0).length === strings.length
  );
}

export function isEmptyString(...strings: string[]): boolean {
  return !isNotEmptyString(...strings);
}

export function mapCategoryToTitle(category: CriterionDto.category, language: Language) {
  switch (category) {
    case CriterionDto.category.COMMON: {
      return language === Languages.uk ? 'Загальне (Common)' : 'Общее (Common)';
    }
    case CriterionDto.category.HTML: {
      return language === Languages.uk ? 'Розмітка (HTML)' : 'Разметка (HTML)';
    }
    case CriterionDto.category.STYLES: {
      return language === Languages.uk ? 'Стилі (Styles)' : 'Стили (Styles)';
    }
    case CriterionDto.category.RESPONSIVE: {
      return language === Languages.uk ? 'Адаптів (Responsive)' : 'Адаптив (Responsive)';
    }
  }
}
