import { useCurrentProjectNav } from '../../../hooks/projects/useCurrentProjectNav';
import { CriterionDto } from '../../../openapi';
import { ProjectNavCriteriaCategory } from './project-nav-criteria-category';

export const ProjectNavCriteriaList = () => {
  const { currentLesson } = useCurrentProjectNav();

  const criteriaByCategory = mapCriteriaByCategories(currentLesson.criteria);

  return (
    <>
      {Object.entries(criteriaByCategory).map(([category, criteria]) => (
        <ProjectNavCriteriaCategory
          criteria={criteria}
          category={category as CriterionDto.category}
          key={category}
        />
      ))}
    </>
  );
};

type MapCriteriaByCategoriesReturnValue = Record<CriterionDto.category, CriterionDto[]>;

function mapCriteriaByCategories(criteria: CriterionDto[]): MapCriteriaByCategoriesReturnValue {
  const criteriaByCategory = {} as MapCriteriaByCategoriesReturnValue;

  for (const criterion of criteria) {
    if (criteriaByCategory[criterion.category]) {
      criteriaByCategory[criterion.category].push(criterion);
      continue;
    }

    criteriaByCategory[criterion.category] = [criterion];
  }

  return criteriaByCategory;
}
