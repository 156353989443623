/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CriterionExample } from './CriterionExample';
import type { Translation } from './Translation';

export type CriterionDto = {
    id: string;
    category: CriterionDto.category;
    number: number;
    description: Translation;
    details?: Translation;
    examples?: Array<CriterionExample>;
    requiredAccess?: CriterionDto.requiredAccess;
}

export namespace CriterionDto {

    export enum category {
        COMMON = 'common',
        HTML = 'html',
        STYLES = 'styles',
        RESPONSIVE = 'responsive',
    }

    export enum requiredAccess {
        GUEST = 'guest',
        FREE = 'free',
        PRO = 'pro',
        PRO_PLUS = 'proPlus',
        EXPERT = 'expert',
        UNLIMITED = 'unlimited',
    }


}