import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getSelectedLessonNumberFromStorage,
  getSelectedProjectIdFromStorage,
} from '../../../shared';
import { State } from './types';

const initialState: State = {
  selectedLessonNumber: getSelectedLessonNumberFromStorage() ?? 1,
  selectedProjectId: getSelectedProjectIdFromStorage() || '',
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    selectProject: (state, action: PayloadAction<string>) => {
      state.selectedProjectId = action.payload;
    },
    selectLessonNumber: (state, action: PayloadAction<number>) => {
      state.selectedLessonNumber = action.payload;
    },
  },
});

export const { selectLessonNumber, selectProject } = projectsSlice.actions;

export default projectsSlice.reducer;
