import { ReactElement } from 'react';
import { Progress } from '@mantine/core';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Transition } from '../utils/transition';
import Logo from '../../assets/logo1.svg';

import './preloader.scss';

export const Preloader = (): ReactElement | null => {
  const loadingProgress = useAppSelector((state) => state.app.initLoadingProgress);
  const status = useAppSelector((state) => state.app.requiredDataStatus);

  return (
    <>
      <Transition mounted={status === 'loading'}>
        <div className='init-preloader'>
          <div className='init-preloader__content'>
            <div>
              <img className={'init-preloader__logo'} src={Logo} alt='логотип mentors-help' />
            </div>

            <Progress value={Number(Math.round(loadingProgress).toFixed())} color='#6668e6' />
          </div>
        </div>
      </Transition>
    </>
  );
};
