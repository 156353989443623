import './call.scss';

type Props = {
  callText: string;
  callLink: string;
  callHint?: string;
  newClass?: string;
};

export const Call = ({ callText, newClass, callLink, callHint }: Props) => {
  return (
    <a className={`call ${newClass}`} href={callLink} target='_blank' title={callHint}>
      <div className='call__head'>{callText}</div>
    </a>
  );
};
