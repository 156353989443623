import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterForm } from '../../components/forms/register-form/register-form';
import { AuthApi, RegisterDto } from "../../openapi";
import { useApi } from '../../hooks/useApi.hook';
import { AuthErrorType } from '../../components/forms/form-error';
import { AlertModal } from '../../components/modal';
import { NavigationRoutes } from '../../shared';
import { useTranslation } from '../../hooks/useTranslation';

export const RegisterFeature = () => {
  const { renderTranslation } = useTranslation();
  const { handleRequest, isLoading, dismissError } = useApi();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [errorInfo, setErrorInfo] = useState<{ isValid: boolean; errorType: AuthErrorType }>({
    isValid: true,
    errorType: '',
  });

  const registerStudent = async (registerUserData: RegisterDto) => {
    const response = await handleRequest(
      AuthApi.authControllerRegister({ requestBody: registerUserData }),
    );

    if (!response) {
      setErrorInfo({ isValid: false, errorType: 'user-exists' });
    }

    setShowModal(true);
    return response;
  };

  const resetErrorInfo = () => {
    setErrorInfo({ isValid: true, errorType: '' });
    dismissError();
  };

  const onModalClose = () => {
    setShowModal(false);
    navigate(NavigationRoutes.login);
  };

  return (
    <>
      <RegisterForm
        onSubmit={registerStudent}
        isLoading={isLoading}
        isValid={errorInfo.isValid}
        errorType={errorInfo.errorType}
        resetErrorInfo={resetErrorInfo}
      />
      <AlertModal
        onModalClose={onModalClose}
        onModalSubmit={onModalClose}
        controls={{ cancelBtn: false, submitBtn: true }}
        show={showModal}
        title={renderTranslation({ ru: 'Успех', uk: 'Успіх' })}>
        <p>
          {renderTranslation({
            ru: 'Вы сможете войти на платформу, когда',
            uk: 'Ви зможете увійти на платформу, коли',
          })}
          <a href='https://t.me/mentors_help_admin' target='_blank'>
            {renderTranslation({ ru: ' администратор ', uk: ' адміністратор ' })}{' '}
          </a>
          {renderTranslation({
            ru: 'подтвердит вашу регистрацию',
            uk: 'підтвердить вашу реєстрацію',
          })}
        </p>
      </AlertModal>
    </>
  );
};
