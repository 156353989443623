import { Avatar } from '../../components/avatar/avatar';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useTranslation } from '../../hooks/useTranslation';

import './about.page.scss';

export const AboutPage = () => {
  const { renderTranslation } = useTranslation();
  useDocumentTitle(renderTranslation({ ru: 'О нас', uk: 'Про нас' }));

  return (
    <main className='page content-page about-page'>
      <div className='container'>
        <div className='box'>
          <div className='page__head'>
            <h1 className='main-title main-title--indent-md'>
              {renderTranslation({ ru: 'О нас', uk: 'Про нас' })}
            </h1>

            <div className='content about-page__content'>
              <p>
                {renderTranslation({
                  ru:
                    'Mentors-help.online - платформа для помощи студентам в освоении навыков frontend-разработки.',
                  uk:
                    'Mentors-help.online – платформа для допомоги студентам у освоєнні навичок frontend-розробки.',
                })}
              </p>
            </div>
          </div>

          <h2 className='main-title main-title--lg main-title--indent'>
            {renderTranslation({ ru: 'Разработка', uk: 'Розробка' })}
          </h2>

          <ul className='team d-flex'>
            <li className='team__item'>
              <div className='team-person'>
                <div className='team-person__head'>
                  <Avatar
                    img='https://avatars.githubusercontent.com/u/29410135?v=4'
                    classes='team-person__avatar'
                  />
                  <div className='team-person__info'>
                    <h3 className='team-person__name'>
                      {renderTranslation({ ru: 'Артем Таланов', uk: 'Артем Таланов' })}{' '}
                    </h3>
                    <p className='team-person__position'>
                      {renderTranslation({
                        ru: 'Founder, Сontent-maker, UI/UX, Frontend-разработчик, Ментор',
                        uk: 'Founder, Сontent-maker, UI/UX, Frontend-розробник, Ментор',
                      })}
                    </p>
                  </div>
                </div>

                <div className='team-person__about'>
                  <p></p>
                </div>
              </div>
            </li>
            <li className='team__item'>
              <div className='team-person'>
                <div className='team-person__head'>
                  <Avatar
                    img='https://avatars.githubusercontent.com/u/62954847?v=4'
                    classes='team-person__avatar'
                  />
                  <div className='team-person__info'>
                    <h3 className='team-person__name'>
                      {renderTranslation({ ru: 'Михаил Новосёлов', uk: 'Михайло Новосьолов' })}
                    </h3>
                    <p className='team-person__position'>
                      {renderTranslation({
                        ru: 'Co-founder, UI/UX, JS Fullstack-разработчик, Ментор',
                        uk: 'Co-founder, UI/UX, JS Fullstack-розробник, Ментор',
                      })}
                    </p>
                  </div>
                </div>
                <div className='team-person__about'>
                  <p></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
};
