import convertHtmlToReact from '@hedgedoc/html-to-react';
import { Tab } from './types';

type Props = {
  title: string;
  html: string;
  type: Tab;
};

export const DetailsListItem = ({ html, title, type }: Props) => {
  return (
    <li className={`requirements-list__group ${type} `}>
      <article className='requirements-list__content'>
        <h3 className={'requirements-list__title'}>{title}</h3>
        {convertHtmlToReact(html)}
      </article>
    </li>
  );
};
