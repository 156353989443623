import { useTranslation } from '../../hooks/useTranslation';

type Props = {
  type: AuthErrorType;
  show: boolean;
};

export const ErrorInfo = ({ type, show }: Props) => {
  const { renderTranslation } = useTranslation();

  const text = getErrorText(type);

  return (
    <div className={`error-info ${show ? 'visible' : ''}`}>
      <p className='error-info__msg'>{renderTranslation(text)}</p>
    </div>
  );
};

export type AuthErrorType = 'expired' | 'credentials' | 'user-exists' | '';

function getErrorText(errorType: AuthErrorType) {
  switch (errorType) {
    case 'credentials': {
      return { ru: 'Неверный email или пароль', uk: 'Невірний email або пароль' };
    }
    case 'expired': {
      return {
        ru: 'Сессия истекла, авторизуйтесь снова',
        uk: 'Сесія закінчилася, авторизуйтесь знову',
      };
    }
    case 'user-exists': {
      return {
        ru: 'Пользователсь с таким email уже существует',
        uk: 'Користувач із таким email вже існує',
      };
    }

    default:
      return { ru: ' ', uk: '' };
  }
}
