import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mantine/core';
import { useAppSelector } from '../../hooks/useAppSelector';
import { NavigationRoutes, setAuthData } from '../../shared';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { logout } from '../user/redux/user-slice';
import { ApiErrorContent } from './modal-content/api-error-content';

import './modal.scss';

export const ApiErrorBoundaryFeature = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isError, message } = useAppSelector((state) => state.app.apiErrorState);

  const isUnauthorizedError = message === 'Unauthorized';

  const reloadPage = () => {
    if (isUnauthorizedError) {
      // We are already at the login page here, so no redirect needed
      location.reload();
      return;
    }

    navigate(NavigationRoutes.login);
    location.reload();
  };

  useEffect(() => {
    if (isUnauthorizedError) {
      dispatch(logout());
      setAuthData({ token: '' });
    }
  }, [message]);

  return (
    <Modal
      className={'api-error-modal'}
      size={'xl'}
      opened={isError}
      onClose={reloadPage}
      withCloseButton={false}
      centered>
      <div className='box'>
        <h1 className='main-title main-title--md main-title--indent alert__title'>Упс!</h1>
        <div className='alert__content'>
          <ApiErrorContent isUnauthorizedError={isUnauthorizedError} />
        </div>
      </div>
    </Modal>
  );
};
