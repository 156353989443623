import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useTranslation } from '../../hooks/useTranslation';

import './donate.page.scss';

export const DonatePage = () => {
  const { renderTranslation } = useTranslation();
  useDocumentTitle(renderTranslation({ ru: 'Поддержать проект', uk: 'Підтримати проект' }));

  return (
    <main className='page content-page donate-page'>
      <div className='container'>
        <div className='box'>
          <div className='page__head '>
            <h1 className='main-title main-title--indent'>
              {renderTranslation({ ru: 'Поддержать проект', uk: 'Підтримати проект' })}
            </h1>
            <div className='page__intro'>
              <p>
                {renderTranslation({
                  ru:
                    'Проект mentors-help развивается благодаря энтузиазму всего двух людей, решивших создать удобную платформу для студентов, которые изучают вёрстку и фронтенд. На данный момент мы развиваем функционал сайта, работаем над созданием нового контента. На всё это уходит колоссальное количество сил и времени. Если наша платформа и материалы приносят вам пользу, то вы можете немного ускорить процессы создания нового контента, выбрав удобный способ поддержать нас.',
                  uk:
                    'Проект mentors-help розвивається завдяки ентузіазму всього двох людей, які вирішили створити зручну платформу для студентів, які вивчають верстку та фронтенд. В даний час ми розвиваємо функціонал сайту, працюємо над створенням нового контенту. На все це йде величезна кількість сил і часу. Якщо наша платформа і матеріали приносять вам користь, ви можете трохи прискорити процеси створення нового контенту, вибравши зручний спосіб підтримати нас.',
                })}
              </p>
            </div>
          </div>

          <div className='content donate-page__content'>
            <ul className='donate-list'>
              <li className='donate-list__item'>
                <a
                  className='donate-item mono'
                  href='https://send.monobank.ua/jar/4bA4orXNME'
                  target='_blank'
                  rel='noopener norefferer'>
                  Монобанка
                </a>
              </li>
              <li className='donate-list__item'>
                <a
                  className='donate-item patreon'
                  href='https://www.patreon.com/mentors_help'
                  target='_blank'
                  rel='noopener norefferer'>
                  {renderTranslation({
                    ru: 'Стать нашим патроном и получить бонусы',
                    uk: 'Стати нашим патроном та отримати бонуси',
                  })}
                </a>
              </li>
              <li className='donate-list__item'>
                <a className='donate-item paypal' target='_blank'>
                  talanovartem@gmail.com
                </a>
              </li>
              {/* <li className='donate-list__item'>
                  <a className='donate-item crypto' href='' target='_blank'>
                    {renderTranslation({ ru: 'Другие способы', uk: 'Інші способи' })}

                  </a>
                </li> */}
              {/* <li className='donate-list__item'>
                  <a className='donate-item donate-item--mono' href=' https://secure.wayforpay.com/donate/mentors_help' target='_blank' rel='noopener norefferer'>
                    WayForPay
                  </a>
                </li> */}
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
};
