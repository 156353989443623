/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SocialMediaDto = {
    mediaTitle: SocialMediaDto.mediaTitle;
    username: string;
    profileLink?: string;
}

export namespace SocialMediaDto {

    export enum mediaTitle {
        TELEGRAM = 'telegram',
    }


}