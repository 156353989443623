import { CriterionDto } from '../../openapi';
import { CriteriaList } from '../../components/criteria/criteria-list/criteria-list';
import { useGetAllCriteriaQuery } from './redux/criteria-api';
import { emptyCriteriaDataState } from './redux/types';

export const CriteriaFeature = () => {
  const { data: criteriaData = emptyCriteriaDataState } = useGetAllCriteriaQuery();

  return (
    <>
      <CriteriaList
        category={CriterionDto.category.COMMON}
        criteria={criteriaData.commonCriteria}
      />
      <CriteriaList category={CriterionDto.category.HTML} criteria={criteriaData.htmlCriteria} />
      <CriteriaList
        category={CriterionDto.category.STYLES}
        criteria={criteriaData.stylesCriteria}
      />
      <CriteriaList
        category={CriterionDto.category.RESPONSIVE}
        criteria={criteriaData.responsiveCriteria}
      />
    </>
  );
};
