import { Middleware } from 'redux';
import { RootState } from '../../../store';
import { setShowDoneCriteriaToStorage, setShowRelevantCriteriaToStorage } from '../../../shared';
import { toggleShowDoneCriteria, toggleShowRelevantCriteria } from './criteria-slice';

export const criteriaLocalStorageMiddleWare: Middleware<{}, RootState> = (storeApi) => (next) => (
  action,
) => {
  if (action.type === toggleShowRelevantCriteria.type) {
    const showRelevantCriteria = storeApi.getState().criteria.showRelevantCriteria;

    setShowRelevantCriteriaToStorage(!showRelevantCriteria);
  }

  if (action.type === toggleShowDoneCriteria.type) {
    const showDoneCriteria = storeApi.getState().criteria.showDoneCriteria;

    setShowDoneCriteriaToStorage(!showDoneCriteria);
  }

  return next(action);
};
