import { useStudyClasses } from './useStudyClasses';
import { useCurrentUser } from './redux/useCurrentUser';

export const useCurrentUserStudyClass = () => {
  const { currentUser } = useCurrentUser();
  const { currentStudyClass } = useStudyClasses();

  const matchesCurrentStudyClass = Boolean(
    currentStudyClass && currentUser.studyClass?.classNumber === currentStudyClass.classNumber,
  );

  return {
    matchesCurrentStudyClass,
    usersStudyClass: currentUser.studyClass?.classNumber ?? null,
  };
};
