import React from 'react';

export const JsErrorContent = () => {
  return (
    <p>
      В додатку сталася помилка, спробуйте оновити сторінку або зв`язатися з
      <a className='link-highlight' href='https://t.me/mentors_help_admin' target='_blank'>
        {' '}
        <b>адміністратором платформи</b>
      </a>
    </p>
  );
};
