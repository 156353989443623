import { Languages } from '../shared';
import { useAppSelector } from './useAppSelector';

export const useTranslation = () => {
  const currentLanguage = useAppSelector((state) => state.app.language);

  const renderTranslation = (values: { ru: string; uk: string }) => {
    return currentLanguage === Languages.ru ? values.ru : values.uk;
  };

  return { renderTranslation, currentLanguage };
};
