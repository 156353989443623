import { ArticleCard } from '../../components/article-card/article-card';
import { useTranslation } from '../../hooks/useTranslation';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

import './tutorials.page.scss';

import tutorialBtns from '../../assets/content-covers/tutorials/tutorial-btns.jpg';
import tutorialBurger from '../../assets/content-covers/tutorials/tutorial-burger.jpg';
import tutorialMixins from '../../assets/content-covers/tutorials/tutorial-mixins.jpg';
import tutorialSprite from '../../assets/content-covers/tutorials/tutorial-sprite.jpg';
import tutorialSvg from '../../assets/content-covers/tutorials/tutorial-svg.jpg';
import tutorialHtml from '../../assets/content-covers/tutorials/tutorial-html.jpg';

export const TutorialsPage = () => {
  const { renderTranslation } = useTranslation();
  useDocumentTitle(renderTranslation({ ru: 'Туториалы', uk: 'Туторіали' }));

  return (
    <main className='page tutorials-page'>
      <div className='container'>
        <section className='box'>
          <div className='page__head'>
            <h1 className='main-title main-title--indent'>
              {renderTranslation({ ru: 'Туториалы', uk: 'Туторіали' })}
            </h1>
            <p className='main-title main-title--md'>
              {renderTranslation({
                ru:
                  'Здесь собраны ссылки на руководства (инструкции) по инструментам, которые мы используем при вёрстке сайтов',
                uk:
                  'Тут зібрані посилання на посібники (інструкції) щодо інструментів, які ми використовуємо при верстці сайтів',
              })}
            </p>
          </div>

          <div className='tutorials-page__content'>
            <ul className='tutorials-list'>
              <li className='tutorials-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Стилизация кнопок/ссылок',
                    uk: 'Стилізація кнопок/посилань',
                  })}
                  cardImage={tutorialBtns}
                  cardLink='https://f0t1.notion.site/e9b2b41ff06346d7855e68058db3c14d'
                  cardLabel='tutorial'
                  cardLevel='light'
                />
              </li>
              <li className='tutorials-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Создание бургер-меню',
                    uk: 'Створення бургер-меню',
                  })}
                  cardImage={tutorialBurger}
                  cardLink='https://f0t1.notion.site/401c87d266fb4e5ab3303c8c5960134f'
                  cardLabel='tutorial'
                  cardLevel='normal'
                />
              </li>
              <li className='tutorials-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Полезные SCSS-миксины',
                    uk: 'Корисні SCSS-міксини',
                  })}
                  cardImage={tutorialMixins}
                  cardLink='https://f0t1.notion.site/SCSS-ba0289b750ad4fb69cc08267cd17960a'
                  cardLabel='tutorial'
                  cardLevel='light'
                />
              </li>
              <li className='tutorials-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Сборка SVG-спрайта для GULP',
                    uk: 'Складання SVG-спрайту для GULP',
                  })}
                  cardImage={tutorialSprite}
                  cardLink='https://f0t1.notion.site/SVG-GULP-026ee0c2163846bab20c77f1b55096fd'
                  cardLabel='tutorial'
                  cardLevel='normal'
                />
              </li>
              <li className='tutorials-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Способы перекрасить SVG',
                    uk: 'Способи перефарбувати SVG',
                  })}
                  cardImage={tutorialSvg}
                  cardLink='https://f0t1.notion.site/SVG-f513e68183244a048857940033ac8eda'
                  cardLabel='tutorial'
                  cardLevel='normal'
                />
              </li>
              <li className='tutorials-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Делим HTML на части. Работа с плагином gulp-file-include',
                    uk: 'Ділимо HTML на частини. Робота з плагіном gulp-file-include',
                  })}
                  cardImage={tutorialHtml}
                  cardLink='https://f0t1.notion.site/HTML-gulp-file-include-ab4d9d697756426a94309094bfb56429'
                  cardLabel='tutorial'
                  cardLevel='normal'
                />
              </li>
            </ul>
          </div>
        </section>
      </div>
    </main>
  );
};
