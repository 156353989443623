import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useUserProjects } from '../../../hooks/projects/useUserProjects';
import { useCurrentProjectNav } from '../../../hooks/projects/useCurrentProjectNav';
import { selectProject } from '../../../features/projects/redux/projects-slice';

import './projects-nav.scss';

export const ProjectsNav = () => {
  const dispatch = useAppDispatch();
  const { projects } = useUserProjects();
  const { currentProject } = useCurrentProjectNav();

  const onClickHandler = (projectId: string) => {
    dispatch(selectProject(projectId));
  };

  return (
    <ul className='criteria-nav__group projects-nav'>
      {projects.map((project) => (
        <li className='projects-nav__item' key={project.id}>
          <button
            className={`btn btn--sm projects-nav__btn ${
              project.id === currentProject.id ? 'active' : ''
            }`}
            type='button'
            onClick={() => onClickHandler(project.id)}>
            {project.title}
          </button>
        </li>
      ))}
    </ul>
  );
};
