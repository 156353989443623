/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RequirementCriteriaDto } from './RequirementCriteriaDto';

export type UpdateRequirementDto = {
    lessonNumber?: number;
    criteriaIds?: Array<RequirementCriteriaDto>;
    taskDescriptions?: string;
    markupDescriptions?: string;
    hints?: string;
    additionalTasks?: string;
    figmaTemplateTitle?: UpdateRequirementDto.figmaTemplateTitle;
}

export namespace UpdateRequirementDto {

    export enum figmaTemplateTitle {
        FRESHOK = 'freshok',
        PORTFOLIO = 'portfolio',
        SIMPLE_FOOD = 'simple food',
    }


}