import { useLocation, useNavigate, Location } from 'react-router-dom';

type LocationWithState = Location & { state: { from: string } | null };

export const useHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return {
    location: location as LocationWithState,
    push: navigate,
    goBack: () => navigate(-1),
    goForward: () => navigate(1),
  };
};
