import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useTranslation } from '../../hooks/useTranslation';
import { ProfileForm } from './profile-form/profile-form';
import { ProfileStats } from './profile-stats/profile-stats';

import './profile.page.scss';

export const ProfilePage = () => {
  const { renderTranslation } = useTranslation();
  useDocumentTitle(renderTranslation({ ru: `Ваш профиль`, uk: 'Ваш профіль' }));

  return (
    <main className='page profile-page'>
      <div className='container'>
        <div className='box'>
          <div className='page__head profile-page__head d-flex'>
            <h1 className='main-title'>
              {renderTranslation({ ru: 'Ваш профиль', uk: 'Ваш профіль' })}
            </h1>
          </div>

          <div className='profile-page__inner d-flex'>
            <section className='profile-page__col'>
              <h2 className='main-title main-title--md main-title--indent'>
                {renderTranslation({ ru: 'Ваша статистика', uk: 'Ваша статистика' })}
              </h2>
              <ProfileStats />
            </section>

            <section className='profile-page__col'>
              <h2 className='main-title main-title--md main-title--indent'>
                {renderTranslation({ ru: 'Личная информация', uk: 'Особиста інформація' })}
              </h2>
              <ProfileForm />
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};
