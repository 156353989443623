import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useTranslation } from '../../hooks/useTranslation';
import { useCurrentProjectNav } from '../../hooks/projects/useCurrentProjectNav';
import { selectLessonNumber } from '../../features/projects/redux/projects-slice';

export const ProjectLessonsNav = () => {
  const dispatch = useAppDispatch();
  const { renderTranslation } = useTranslation();
  const { currentProject } = useCurrentProjectNav();

  const lessonNumbers = currentProject.lessons.map((lesson) => lesson.number);
  const selectedLessonNumber = useAppSelector((state) => state.projects.selectedLessonNumber);

  const onClickHandler = (lessonNumber: number) => {
    dispatch(selectLessonNumber(lessonNumber));
  };

  return (
    <>
      <div className='criteria-nav__group'>
        <h3 className='criteria-nav__title text-center'>
          {renderTranslation({ ru: 'Выберите номер задания', uk: 'Оберіть номер завдання' })}
        </h3>
        <ul className='criteria-nav__btns'>
          {lessonNumbers.map((number) => (
            <li key={number}>
              <button
                className={`btn btn--sm criteria-nav__btn ${
                  selectedLessonNumber === number ? 'active' : ''
                }`}
                onClick={() => onClickHandler(number)}>
                {number}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
