import { CriteriaProgressItemDto, UserDto } from '../openapi';

const { MENTOR, ADMIN, STUDENT } = UserDto.role;
const { DONE, TODO } = CriteriaProgressItemDto.status;

export const isAdminRole = (role: string) => role === ADMIN;
export const isMentorRole = (role: string) => role === MENTOR;
export const isStudentRole = (role: string) => role === STUDENT;

export const lessonStatusIsDone = (status: string) => status === DONE;
export const lessonStatusIsTodo = (status: string) => status === TODO;
