import { RequirementDto } from '../openapi';

export type FigmaProject = RequirementDto.figmaTemplateTitle;

export type PasswordErrors = {
  length: { valid: boolean; message: Translations };
  cyrillic: { valid: boolean; message: Translations };
  duplicate: { valid: boolean; message: Translations };
  passwordConfirmation: { valid: boolean; message: Translations };
  valid: boolean;
};

export const defaultPasswordErrors: PasswordErrors = {
  length: {
    valid: true,
    message: {
      ru: 'Пароль должен быть не менее 6 символов',
      uk: 'Пароль має бути не менше 6 символів',
    },
  },
  cyrillic: {
    valid: true,
    message: { ru: 'Пароль должен быть на латинице', uk: 'Пароль має бути на латиниці' },
  },
  duplicate: {
    valid: true,
    message: {
      ru: 'Новый пароль должен отличаться от старого',
      uk: 'Новий пароль має відрізнятися від старого',
    },
  },
  passwordConfirmation: {
    valid: true,
    message: { ru: 'Пароли не совпадают', uk: 'Паролі не співпадають' },
  },
  valid: true,
};

export type Language = 'uk' | 'ru';

export type Translations = { uk: string; ru: string };

export type ArticleCardLabelType =
  | 'article'
  | 'instruction'
  | 'secrets'
  | 'tutorial'
  | 'design'
  | 'task';

export type Normalized<T> = Record<string, T>;
