import { Outlet } from 'react-router-dom';
import { PagesHeader } from '../../../components/pages-header/pages-header';
import { PagesFooter } from '../../../components/pages-footer/pages-footer';

export const PagesWrapper = () => {
  return (
    <>
      <PagesHeader />
      <Outlet />
      <PagesFooter />
    </>
  );
};
