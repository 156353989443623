import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo1.svg';
import logoSM from '../../assets/logo-sm.svg';
import maleAvatar from '../../assets/avatar-man.svg';
import femaleAvatar from '../../assets/avatar-woman.svg';
import { Avatar } from '../avatar/avatar';
import { useClickOutside } from '../../hooks/useClickOutside';
import { FemaleGender, setAuthData } from '../../shared';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { logout } from '../../features/user/redux/user-slice';
import { AlertModal } from '../modal';
import { useTranslation } from '../../hooks/useTranslation';
import { toggleBlur, toggleLanguage } from '../../features/app/app-slice';
import { useCurrentUser } from '../../hooks/redux/useCurrentUser';

import './pages-header.scss';

export const PagesHeader = () => {
  const dispatch = useAppDispatch();
  const { renderTranslation } = useTranslation();
  const { currentUser } = useCurrentUser();

  const [isOpen, setIsOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const headerNode = useClickOutside(() => {
    setIsOpen(false);
  });

  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  const onPageChange = () => {
    setIsOpen(!isOpen);
  };

  const onLogoutBtnClickHandler = () => {
    setIsLogoutModalOpen(true);
  };

  const hideLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const onLogoutConfirm = () => {
    setAuthData({ token: '' });
    dispatch(logout());
    window.location.reload();
  };

  const onLanguageChange = () => {
    dispatch(toggleBlur());
    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      dispatch(toggleLanguage());
    }, 400);

    setTimeout(() => {
      dispatch(toggleBlur());
      document.body.style.overflow = 'initial';
    }, 1000);
  };

  return (
    <header className='pages-header' ref={headerNode}>
      <div className='container'>
        <div className='pages-header__inner d-flex'>
          <a className='logo pages-header__logo'>
            <picture>
              <source srcSet={logoSM} media='(max-width: 576px)' />
              <img className='logo__img' src={logo} alt='Логотип Mentors-help' />
            </picture>
          </a>

          <button
            className={`pages-header__btn ${isOpen ? 'active' : ''}`}
            onClick={onClickHandler}
            type='button'>
            <span className='sr-only'>
              {renderTranslation({ ru: 'Открыть меню', uk: 'Відкрити меню' })}
            </span>
          </button>

          <nav className={`pages-menu ${isOpen ? 'active' : ''}`}>
            <Avatar
              classes='pages-menu__avatar'
              username={formatUsername(currentUser?.fullName)}
              img={currentUser?.gender === FemaleGender ? femaleAvatar : maleAvatar}
            />

            <ul className='pages-menu__list'>
              <li className='pages-menu__item'>
                <Link
                  className='pages-menu__link pages-menu__link--profile'
                  to='/profile'
                  onClick={onPageChange}>
                  {renderTranslation({ ru: 'Профиль', uk: 'Профіль' })}
                </Link>
              </li>
              <li className='pages-menu__item'>
                <Link
                  className='pages-menu__link pages-menu__link--projects'
                  to='/projects'
                  onClick={onPageChange}>
                  {renderTranslation({ ru: 'Проекты', uk: 'Проєкти' })}
                </Link>
              </li>
              <li className='pages-menu__item'>
                <Link
                  className='pages-menu__link pages-menu__link--list'
                  to='/criteria'
                  onClick={onPageChange}>
                  {renderTranslation({ ru: 'Критерии', uk: 'Критерії' })}
                </Link>
              </li>
              <li className='pages-menu__item'>
                <Link
                  className='pages-menu__link pages-menu__link--page'
                  to='/student-page'
                  onClick={onPageChange}>
                  {renderTranslation({ ru: 'Страница студента', uk: 'Сторінка студента' })}
                </Link>
              </li>
              <li className='pages-menu__item'>
                <Link
                  className='pages-menu__link pages-menu__link--article'
                  to='/articles'
                  onClick={onPageChange}>
                  {renderTranslation({ ru: 'Статьи', uk: 'Статті' })}
                </Link>
              </li>
              <li className='pages-menu__item'>
                <Link
                  className='pages-menu__link pages-menu__link--tasks'
                  to='/tasks'
                  onClick={onPageChange}>
                  {renderTranslation({ ru: 'Задания', uk: 'Завдання' })}
                </Link>
              </li>
              <li className='pages-menu__item'>
                <Link
                  className='pages-menu__link pages-menu__link--tutorial'
                  to='/tutorials'
                  onClick={onPageChange}>
                  {renderTranslation({ ru: 'Туториалы', uk: 'Туторіали' })}
                </Link>
              </li>
              <li className='pages-menu__item'>
                <button
                  className='pages-menu__link pages-menu__link--exit pages-menu__link--btn'
                  onClick={onLogoutBtnClickHandler}>
                  {renderTranslation({ ru: 'Выход', uk: 'Вихід' })}
                </button>
              </li>
            </ul>

            <div className='pages-menu__footer'>
              <button
                className='pages-menu__link pages-menu__link--special pages-menu__link--btn pages-menu__link--language'
                onClick={onLanguageChange}>
                {renderTranslation({
                  ru: 'Сменить язык на украинский',
                  uk: 'Змінити мову на російську',
                })}
              </button>
              <a
                className='pages-menu__link pages-menu__link--special pages-menu__link--mistake'
                target='_blank'
                rel='noopener'
                href='https://t.me/mentors_help_admin'>
                {renderTranslation({ ru: 'Сообщить об ошибке?', uk: 'Повідомити про помилку' })}
              </a>
            </div>
          </nav>

          <Link to='/consult' className='btn pages-header__order'>
            <span>{renderTranslation({ ru: 'Хочу консультацию', uk: 'Хочу консультацію' })}</span>
          </Link>

          <Link to='/donate' className='pages-header__donate' title='Помочь проекту'>
            <span className='sr-only'>
              {renderTranslation({ ru: 'Помочь проекту', uk: 'Підтримати проєкт' })}
            </span>
          </Link>
        </div>
      </div>

      <AlertModal
        show={isLogoutModalOpen}
        onModalClose={hideLogoutModal}
        onModalSubmit={onLogoutConfirm}
        controls={{ cancelBtn: true, submitBtn: true }}
        title={renderTranslation({ ru: 'Подтверждение', uk: 'Підтвердження' })}>
        <>
          <p>{renderTranslation({ ru: 'Выйти из аккаунта?', uk: 'Вийти з аккаунту?' })}</p>
          <p className='form__text-sm'>
            {renderTranslation({
              ru: 'Вам потребуется снова ввести свои данные при следующем входе',
              uk: 'Вам потрібно буде знову ввести свої дані при наступному вході',
            })}
          </p>
        </>
      </AlertModal>
    </header>
  );
};

function formatUsername(fullName = '') {
  const splitFullName = fullName.split(' ');

  if (splitFullName.length === 1) return splitFullName[0];

  return `${splitFullName[0]} ${splitFullName[1][0]}.`;
}
