import { Translations } from '../../../shared';
import { Tab } from './types';

export const tabsButtons: { translation: Translations; tab: Tab }[] = [
  {
    translation: {
      ru: 'Показать / скрыть задачи',
      uk: 'Показати / Сховати задачі',
    },
    tab: Tab.tasks,
  },
  {
    translation: {
      ru: 'Показать / скрыть пункты задания',
      uk: 'Показати / сховати пункти завдання',
    },
    tab: Tab.work,
  },
  {
    translation: {
      ru: 'Показать / скрыть подсказки',
      uk: 'Показати / сховати підказки',
    },
    tab: Tab.hints,
  },
  {
    translation: {
      ru: 'Показать / скрыть дополнительные задания',
      uk: 'Показати / сховати додаткові завдання',
    },
    tab: Tab.plus,
  },
];
