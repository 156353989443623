import React from 'react';
import { Modal } from '@mantine/core';
import { JsErrorContent } from './modal-content/js-error-content';

type State = { hasError: boolean };

type Props = { children: JSX.Element };

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleClose() {}

  render() {
    return (
      <>
        {!this.state.hasError && this.props.children}

        <Modal
          className={'api-error-modal'}
          size={'xl'}
          opened={this.state.hasError}
          onClose={this.handleClose}
          withCloseButton={false}
          centered>
          <div className='box'>
            <h1 className='main-title main-title--md main-title--indent alert__title'>Упс!</h1>
            <div className='alert__content'>
              <JsErrorContent />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
