import './article-card.scss';
import { useTranslation } from '../../hooks/useTranslation';
import { ArticleCardLabelType, Translations } from '../../shared';

type Props = {
  // isInternal?: boolean;
  cardImage: string;
  cardLabel: ArticleCardLabelType;
  cardName: string;
  cardLink: string;
  cardLevel?: 'light' | 'normal' | 'hard';
  designType?: string;
};

export const ArticleCard = ({
  cardImage,
  cardLabel,
  cardName,
  cardLink,
  cardLevel,
  designType,
}: // isInternal,
Props) => {
  const { renderTranslation } = useTranslation();

  const labelName = renderTranslation(getLabelTranslation(cardLabel));
  const contentLevel = renderTranslation(getContentLevelTranslation(cardLevel ?? ''));

  // додати умову, що перевырятиме тим посилання і віддаватиме потрібний елеменент. Якщо isInternal, то отримаємо <Link...>, якщо ні, то звичайне посилання

  return (
    <article className={`article-card ${cardLabel}`}>
      <a
        href={cardLink}
        target='_blank'
        title={renderTranslation({
          ru: `Перейти на страницу ${cardName}`,
          uk: `Перейти на сторінку ${cardName}`,
        })}
        rel='noreferrer noopener'>
        <div className='article-card__head' style={{ backgroundImage: `url(${cardImage})` }}>
          <span className='article-card__label'>{labelName}</span>
          {designType ? (
            <span
              className={`article-card__design article-card__design--${designType}`}
              title={`макет в ${designType}`}>
              {designType}
            </span>
          ) : null}
        </div>
        <div className='article-card__foot'>
          {cardLevel ? (
            <div className={`article-card__level`}>
              {renderTranslation({
                ru: `Уровень`,
                uk: `Рівень`,
              })}
              :{' '}
              <span className={`article-card__star  article-card__star--${cardLevel}`}>
                {contentLevel}
              </span>
            </div>
          ) : null}
          <h3 className='article-card__name'>{cardName}</h3>
        </div>
      </a>
    </article>
  );
};

function getLabelTranslation(cardLabel: string): Translations {
  switch (cardLabel) {
    case 'instruction':
      return { ru: 'Инструктаж', uk: 'Інструктаж' };

    case 'secrets':
      return { ru: 'Секреты', uk: 'Секрети' };

    case 'design':
      return { ru: 'Макет', uk: 'Макет' };

    case 'tutorial':
      return { ru: 'Туториал', uk: 'Туторіал' };

    case 'task':
      return { ru: 'Задание', uk: 'Завдання' };

    default:
      return { ru: 'Статья', uk: 'Стаття' };
  }
}

function getContentLevelTranslation(cardLevel: string): Translations {
  switch (cardLevel) {
    case 'normal':
      return { ru: 'Нормальный', uk: 'Нормальний' };

    case 'hard':
      return { ru: 'Сложный', uk: 'Складний' };

    default:
      return { ru: 'Простой', uk: 'Простий' };
  }
}
