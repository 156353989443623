import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';

export const ConfirmModal = () => {
  const { renderTranslation } = useTranslation();

  return (
    <>
      <p>
        {renderTranslation({
          ru: 'Вы уверены что хотите снять отметку о выполнении критерия?',
          uk: 'Ви впевнені, що хочете зняти відмітку про виконання критерію?',
        })}
      </p>
      <p className='form__text-sm'>
        {renderTranslation({
          ru: 'Ментор не сможет принять задание в случае невыполнения каких-либо пунктов',
          uk: 'Ментор не зможе прийняти завдання у випадку невиконання яких-небудь пунктів',
        })}
      </p>
    </>
  );
};
