import React from 'react';
import { Tab } from './types';

type Props = {
  activeTab: Tab;
  tabValue: Tab;
  title: string;
  onClick: (tab: Tab) => void;
};
export const TabItem = ({ activeTab, tabValue, title, onClick }: Props) => {
  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const target = event.target as Element;

    if (target.classList.contains('active')) {
      target.classList.remove('active');
    }

    onClick(tabValue);
  };

  return (
    <li className='requirements__item'>
      <button
        className={`btn requirements__type requirements__type--${tabValue} ${
          activeTab === tabValue ? 'active' : ''
        } `}
        // id={tabValue}
        title={title}
        onClick={onClickHandler}>
        <span className='sr-only'>{title}</span>
      </button>
    </li>
  );
};
