import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import criteriaReducer from '../features/criteria/redux/criteria-slice';
import userReducer from '../features/user/redux/user-slice';
import appReducer from '../features/app/app-slice';
import projectsReducer from '../features/projects/redux/projects-slice';
import { criteriaLocalStorageMiddleWare } from '../features/criteria/redux/middleware';
import { appApi } from '../features/app/app-api';
import {
  projectsLocalStorageMiddleWare,
  selectProjectOnAppInitMiddleware,
} from '../features/projects/redux/middleware';
import { rtkQueryErrorSetterMiddleware } from '../features/app/middleware';

const rootReducer = combineReducers({
  criteria: criteriaReducer,
  user: userReducer,
  app: appReducer,
  projects: projectsReducer,
  [appApi.reducerPath]: appApi.reducer,
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      criteriaLocalStorageMiddleWare,
      projectsLocalStorageMiddleWare,
      selectProjectOnAppInitMiddleware,
      appApi.middleware,
      rtkQueryErrorSetterMiddleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
