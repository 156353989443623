import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '@mantine/core';
import { PasswordInput } from '../../../components/forms/password-input/passwordInput';
import { defaultPasswordErrors, FemaleGender, MaleGender, PasswordErrors } from '../../../shared';
import { useUserPassword } from '../../../hooks/useUserPassword';
import { SocialMediaDto, UpdateProfileDto, UserDto } from '../../../openapi';
import { usePasswordValidation } from '../../../hooks/usePasswordValidation';
import { AutoCloseModal } from '../../../components/modal/auto-close-modal';
import { useTranslation } from '../../../hooks/useTranslation';
import { useCurrentUser } from '../../../hooks/redux/useCurrentUser';
import { useUpdateUserProfileMutation } from '../../../features/user/redux/user-api';

import '../../../components/forms/main-form.scss';
import '../profile-form/profile-form.scss';

const noMentorEmail = 'no-mentor@gmail.com';
const TelegramMediaTitle = SocialMediaDto.mediaTitle.TELEGRAM;

export const ProfileForm = () => {
  const { renderTranslation } = useTranslation();
  const { currentUser, isStudentRole } = useCurrentUser();
  // TODO: remove password value from the FE
  const { password, refreshPassword } = useUserPassword();
  const { isValidationLoading, validate } = usePasswordValidation();
  const [
    updateProfile,
    { isLoading, isSuccess, reset: resetMutation },
  ] = useUpdateUserProfileMutation();

  const [showChangePasswordFields, setShowChangePasswordFields] = useState(false);
  const [errorState, setErrorState] = useState(errorsState);
  const [showAlert, setShowAlert] = useState(false);

  const [values, setValues] = useState<FieldsState>(setDefaultState());

  useEffect(() => {
    if (password) {
      setValues({
        ...values,
        password,
      });
    }
  }, [password]);

  useEffect(() => {
    setShowAlert(isSuccess);
  }, [isSuccess]);

  function setDefaultState() {
    const { fullName, gender, socialMedia, dateOfBirth } = currentUser;
    const telegram = useMemo(
      () => socialMedia?.find((media) => media.mediaTitle === TelegramMediaTitle),
      [socialMedia],
    );

    return {
      ...emptyState,
      ...{
        fullName,
        ...(gender && { gender }),
        ...(dateOfBirth && { dateOfBirth }),
        ...(gender && { gender }),
        ...(telegram && { telegram: telegram.username }),
      },
    };
  }

  const updateValues = (key: keyof FieldsState, value: string) => {
    setValues({ ...values, [key]: value });
  };

  const onFullNameChange = (value: string) => {
    updateValues('fullName', value);
  };

  const onGenderChange = (value: typeof MaleGender | typeof FemaleGender) => {
    updateValues('gender', value);
  };

  const onChangePasswordBtnClick = () => {
    setShowChangePasswordFields(!showChangePasswordFields);
  };
  const onPasswordChange = (value: string) => {
    updateValues('password', value);
  };
  const onNewPasswordChange = (value: string) => {
    updateValues('newPassword', value);
  };
  const onConfirmNewPasswordChange = (value: string) => {
    updateValues('confirmNewPassword', value);
  };
  const onTelegramChange = (value: string) => {
    updateValues('telegram', value);
  };
  const onDateOfBirthChange = (value: string) => {
    updateValues('dateOfBirth', new Date(value).toISOString());
  };

  const validatePassword = async () => {
    const { newPassword, confirmNewPassword } = values;
    const validationResult = await validate(newPassword, confirmNewPassword);

    setErrorState(validationResult);

    return validationResult;
  };

  const onSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (showChangePasswordFields && !(await validatePassword()).valid) {
      return;
    }

    const { fullName, gender, dateOfBirth, socialMedia } = currentUser;

    const telegram = socialMedia?.find((media) => media.mediaTitle === TelegramMediaTitle);
    const updatedSocialMedia =
      values.telegram.length && values.telegram !== telegram?.username
        ? [{ mediaTitle: SocialMediaDto.mediaTitle.TELEGRAM, username: values.telegram }]
        : null;

    const propsToUpdate: UpdateProfileDto = {
      ...(socialMedia?.length && { socialMedia }),
      ...(values.fullName !== fullName && { fullName: values.fullName }),
      ...(values.gender !== gender && { gender: values.gender }),
      ...(showChangePasswordFields &&
        values.newPassword !== password && { password: values.newPassword }),
      ...(updatedSocialMedia && { socialMedia: updatedSocialMedia }),
      ...(values.dateOfBirth?.length &&
        values.dateOfBirth !== dateOfBirth && { dateOfBirth: values.dateOfBirth }),
    };

    updateProfile(propsToUpdate);
  };

  const onAlertClose = async () => {
    resetMutation();

    setValues({
      ...values,
      password: await refreshPassword(),
      newPassword: '',
      confirmNewPassword: '',
    });
  };

  const showAddMentorBtn =
    isStudentRole && (!currentUser.mentorEmail || currentUser.mentorEmail === noMentorEmail);

  return (
    <form className='main-form profile-form' onSubmit={(event) => event.preventDefault()}>
      {/* <div className='main-form__group'>
                        <figure className='avatar'>
                            <img src='' className='avatar__img' alt='Фото профиля' />
                        </figure>
                        <label htmlFor='avatar' className='profile-form__add-photo'><span className='sr-only'>Изменить фото профиля</span></label>
                        <input id='avatar' type='file' className='sr-only profile-form__add-photo' />
                    </div> */}

      <div className='main-form__group'>
        <label htmlFor='profileForm[name]' className='main-form__label'>
          {renderTranslation({ ru: 'Имя и Фамилия', uk: "Ім'я та прізвище" })} <span>*</span>
        </label>
        <input
          id='profileForm[name]'
          name='profileForm[name]'
          type='text'
          placeholder={!values.fullName.length ? 'Введите ваши имя и фамилию' : ''}
          className='main-form__input'
          required
          value={values.fullName}
          onChange={(event) => onFullNameChange(event.target.value)}
        />
      </div>

      <div className='main-form__group'>
        <label className='main-form__label'>{renderTranslation({ ru: 'Пол', uk: 'Стать' })}</label>

        <div className='d-flex profile-form__gender'>
          <div className='main-form__radio-group'>
            <input
              className='main-form__radio main-radio__radio'
              id='profileForm[gender-male]'
              type='radio'
              name='gender'
              value='male'
              checked={values.gender === MaleGender}
              onChange={() => onGenderChange(MaleGender)}
            />
            <label
              className='main-form__radio-label main-radio__label'
              htmlFor='profileForm[gender-male]'>
              {renderTranslation({ ru: 'Муж', uk: 'Чол' })}
            </label>
          </div>
          <div className='main-form__radio-group'>
            <input
              className='main-form__radio main-radio__radio'
              id='profileForm[gender-female]'
              type='radio'
              name='gender'
              value='female'
              checked={values.gender === FemaleGender}
              onChange={() => onGenderChange(FemaleGender)}
            />
            <label
              className='main-form__radio-label main-radio__label'
              htmlFor='profileForm[gender-female]'>
              {renderTranslation({ ru: 'Жен', uk: 'Жін' })}
            </label>
          </div>
        </div>
      </div>

      <div className='main-form__group main-form__group--with-hint'>
        <label htmlFor='profileForm[email]' className='main-form__label d-flex'>
          Email{' '}
          <div className='main-form__hint'>
            <span>?</span>
            <p>
              {renderTranslation({
                ru: 'Для смены email обратитесь к',
                uk: 'Для зміни email зверніться до',
              })}{' '}
              <a href='https://t.me/mentors_help_admin' target='_blank'>
                {renderTranslation({ ru: 'администратору сайта', uk: 'адміністрації сайту' })}{' '}
              </a>
            </p>
          </div>
        </label>
        <input
          id='profileForm[email]'
          type='text'
          className='main-form__input'
          disabled
          value={currentUser?.email ?? ''}
        />
      </div>

      <div className='main-form__group main-form__group--with-btn'>
        <label htmlFor='profileForm[mentor]' className='main-form__label'>
          {renderTranslation({ ru: 'Ваш ментор', uk: 'Ваш ментор' })}
        </label>
        <input
          id='profileForm[mentor]'
          type='text'
          className='main-form__input'
          value={currentUser?.mentorEmail ?? ''}
          disabled
        />
        {showAddMentorBtn && (
          <Link
            to='/consult'
            className='btn btn--sm btn--green'
            title={renderTranslation({
              ru: 'Перейти на страницу заказа консультации',
              uk: 'Перейти на сторінку замовлення консультації',
            })}>
            {renderTranslation({ ru: 'Добавить наставника', uk: 'Додати наставника' })}
          </Link>
        )}
      </div>

      <div className='main-form__group main-form__group--with-btn'>
        <PasswordInput
          id='profileForm[pass]'
          text={renderTranslation({ ru: 'Пароль', uk: 'Пароль' })}
          disabledAttr
          value={values.password}
          onChange={onPasswordChange}
        />
        <button
          type='button'
          className='btn btn--sm'
          onClick={onChangePasswordBtnClick}
          disabled={isLoading}>
          {renderTranslation({ ru: 'Сменить пароль?', uk: 'Змінити пароль?' })}
        </button>
      </div>

      <fieldset className={`profile-form__group ${showChangePasswordFields ? 'open' : ''}`}>
        <div className={`main-form__group ${!errorState.valid ? 'main-form__group--error' : ''}`}>
          <PasswordInput
            id='profileForm[passNew]'
            placeholder={renderTranslation({
              ru: 'Введите пароль, не менее 6 символов',
              uk: 'Введіть пароль, не менше 6 символів',
            })}
            text={renderTranslation({ ru: 'Новый пароль', uk: 'Новий пароль' })}
            value={values.newPassword}
            onChange={onNewPasswordChange}
          />
          {!errorState.length.valid && (
            <div className='main-form__message'>{renderTranslation(errorState.length.message)}</div>
          )}
          {!errorState.cyrillic.valid && (
            <div className='main-form__message'>
              {renderTranslation(errorState.cyrillic.message)}
            </div>
          )}
          {!errorState.duplicate.valid && (
            <div className='main-form__message'>
              {renderTranslation(errorState.duplicate.message)}
            </div>
          )}
        </div>
        <div
          className={`main-form__group ${
            !errorState.passwordConfirmation.valid ? 'main-form__group--error' : ''
          }`}>
          <PasswordInput
            id='profileForm[passConfirm]'
            placeholder={renderTranslation({
              ru: 'Повторите пароль, указанный выше',
              uk: 'Повторіть пароль, вказаний вище',
            })}
            text={renderTranslation({ ru: 'Подтвердить пароль', uk: 'Підтвердити пароль' })}
            value={values.confirmNewPassword}
            onChange={onConfirmNewPasswordChange}
          />
          <div className='main-form__message'>
            {!errorState.passwordConfirmation.valid &&
              `${renderTranslation(errorState.passwordConfirmation.message)}`}
          </div>
        </div>
        {isValidationLoading ? (
          <Loader color={'rgb(102, 104, 230)'} />
        ) : (
          <>
            <button className='btn btn--sm' type='button' onClick={validatePassword}>
              {renderTranslation({
                ru: 'Подтвердить смену пароля',
                uk: 'Підтвердити зміну пароля',
              })}
            </button>
          </>
        )}
      </fieldset>

      <div className='main-form__group'>
        <label htmlFor='profileForm[telegram]' className='main-form__label'>
          Telegram
        </label>
        <input
          id='profileForm[telegram]'
          type='text'
          className='main-form__input'
          placeholder={renderTranslation({
            ru: 'Введите ваш ник из телеграм',
            uk: 'Введіть ваш нік з телеграм',
          })}
          value={values.telegram}
          onChange={(event) => onTelegramChange(event.target.value)}
        />
      </div>

      <div className='main-form__group'>
        <label htmlFor='' className='main-form__label'>
          {renderTranslation({ ru: 'Дата рождения', uk: 'Дата народження' })}
        </label>
        <input
          type='date'
          className='main-form__input'
          placeholder='Введите дату рождения'
          value={values.dateOfBirth ? formatDateToInputValue(values.dateOfBirth) : ''}
          onChange={(event) => onDateOfBirthChange(event.target.value)}
        />
      </div>

      {isLoading ? (
        <Loader color={'#41C965'} />
      ) : (
        <button className='btn btn--lg btn--green profile-form__btn' onClick={onSubmit}>
          {renderTranslation({ ru: 'Сохранить изменения', uk: 'Зберегти зміни' })}
        </button>
      )}

      <p>
        {renderTranslation({
          ru: '* - поля, обязательные для заполнения',
          uk: "* - поля, обов'язкові для заповнення",
        })}
      </p>

      <AutoCloseModal
        text={renderTranslation({
          ru: 'Данные были успешно обновлены',
          uk: 'Дані були успішно оновлені',
        })}
        show={showAlert}
        closeDelay={1000}
        onClose={onAlertClose}
      />
    </form>
  );
};

function formatDateToInputValue(dateString: string) {
  const date = new Date(dateString);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  return date.toJSON().slice(0, 10);
}

type FieldsState = Pick<UserDto, 'fullName' | 'gender' | 'password' | 'dateOfBirth'> & {
  newPassword: string;
  confirmNewPassword: string;
  telegram: string;
};

const emptyState: FieldsState = {
  fullName: '',
  password: '',
  newPassword: '',
  confirmNewPassword: '',
  telegram: '',
};

const errorsState: PasswordErrors = { ...defaultPasswordErrors };
