import { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atelierHeathLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import { CriterionDto } from '../../../openapi';
import { useTranslation } from '../../../hooks/useTranslation';

type Props = Pick<CriterionDto, 'details' | 'examples'> & { buttonText: string };

export const CardAccordion = ({ details, examples, buttonText }: Props) => {
  const { renderTranslation } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className='criteria-card__more'>
      <button
        className={`criteria-card__accordion ${isExpanded ? 'opened' : ''}`}
        type='button'
        onClick={() => setIsExpanded(!isExpanded)}>
        {buttonText}
      </button>

      {details && (
        <div className='criteria-card__info'>
          {convertHtmlToReact(renderTranslation(details), {})}
        </div>
      )}

      {examples && (
        <div className='criteria-card__info'>
          {examples.map((example, idx) => (
            <div key={example.id}>
              {example.description && (
                <p>{convertHtmlToReact(renderTranslation(example.description), {})}</p>
              )}
              <SyntaxHighlighter language={example.language} style={atelierHeathLight} key={idx}>
                {renderTranslation(example.code)}
              </SyntaxHighlighter>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
