/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LessonsReview = {
    lessonNumber: number;
    score?: number;
    status: LessonsReview.status;
    retakeCount?: number;
}

export namespace LessonsReview {

    export enum status {
        DONE = 'done',
        NOT_DONE = 'not_done',
        RETAKE = 'retake',
    }


}