import { CriterionDto } from '../../../openapi';
import { createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../app/app-api';
import { CriteriaDataState } from './types';

const { COMMON, STYLES, HTML, RESPONSIVE } = CriterionDto.category;

const getAllCriteriaEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint('criteria'),
};

export const criteriaApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCriteria: builder.query<CriteriaDataState, void>({
      query: () => ({ url: getAllCriteriaEndpoint.path, method: getAllCriteriaEndpoint.method }),
      transformResponse: (response: CriterionDto[]) => ({
        commonCriteria: response.filter((criterion) => criterion.category === COMMON) ?? [],
        htmlCriteria: response.filter((criterion) => criterion.category === HTML) ?? [],
        stylesCriteria: response.filter((criterion) => criterion.category === STYLES) ?? [],
        responsiveCriteria: response.filter((criterion) => criterion.category === RESPONSIVE) ?? [],
      }),
    }),
  }),
});

export const { useGetAllCriteriaQuery } = criteriaApi;
