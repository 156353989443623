import { createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../app/app-api';
import { ProjectDto } from '../../../openapi';

const baseUrl = 'project/';

const getUserProjects: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(baseUrl + 'user'),
};

export const projectsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersProjects: builder.query<ProjectDto[], void>({
      query: () => ({ url: getUserProjects.path, method: getUserProjects.method }),
    }),
  }),
});

export const { useGetUsersProjectsQuery } = projectsApi;
