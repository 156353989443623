import { useMemo } from 'react';
import { useAppSelector } from '../useAppSelector';
import { currentUserSelector } from '../../features/user/redux/user-api';
import { normalizeArray, Normalized } from '../../shared';
import { CriteriaProgressItemDto, CurrentUserDto, UserDto, UserProjectDto } from '../../openapi';

const { MENTOR, ADMIN, STUDENT } = UserDto.role;

type ReturnValue = {
  currentUser: CurrentUserDto;
  currentUserProject: UserProjectDto;
  normalizedUserProjects: Normalized<UserProjectDto>;
  normalizedUserCriteriaProgress: Normalized<CriteriaProgressItemDto>;
  userCriteriaProgress: CriteriaProgressItemDto[];
  isAdminRole: boolean;
  isMentorRole: boolean;
  isStudentRole: boolean;
};

export const useCurrentUser = (): ReturnValue => {
  // RTK query subscription with 'skipToken' arg does not get updated value
  // So moved to useAppSelector to just get data, not fetch it.
  // currentUser must be defined, thanks to useRequiredDataLoader hook
  const currentUser = useAppSelector(currentUserSelector)!;
  const { role } = currentUser;

  const isAdminRole = role === ADMIN;
  const isMentorRole = role === MENTOR;
  const isStudentRole = role === STUDENT;

  const { selectedProjectId } = useAppSelector((state) => state.projects);
  const normalizedUserProjects = useMemo(
    () => normalizeArray<UserProjectDto>(currentUser.projects, 'projectId'),
    [currentUser],
  );

  const currentUserProject = useMemo(() => normalizedUserProjects[selectedProjectId], [
    normalizedUserProjects,
    selectedProjectId,
  ]);

  const normalizedUserCriteriaProgress = useMemo(
    () =>
      normalizeArray<CriteriaProgressItemDto>(currentUserProject.criteriaProgress, 'criterionId'),
    [currentUserProject],
  );

  return {
    currentUser,
    currentUserProject,
    userCriteriaProgress: currentUserProject.criteriaProgress,
    normalizedUserCriteriaProgress,
    normalizedUserProjects,
    isAdminRole,
    isMentorRole,
    isStudentRole,
  };
};
