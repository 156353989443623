import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/useAppSelector';

export const RequireInitData = () => {
  const dataStatus = useAppSelector((state) => state.app.requiredDataStatus);
  const isLoaded = dataStatus === 'ready';

  return <>{isLoaded && <Outlet />}</>;
};
