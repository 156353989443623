import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Languages } from '../../shared';
import { AppApiError, RequiredDataStatusType, State } from './types';

const initialState: State = {
  language: Languages.uk,
  apiErrorState: { isError: false, message: '' },
  isBlurVisible: false,
  initLoadingProgress: 0,
  requiredDataStatus: 'unset',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleLanguage: (state) => {
      state.language = state.language === Languages.uk ? Languages.ru : Languages.uk;
    },
    setApiError: (state, action: PayloadAction<AppApiError>) => {
      state.apiErrorState = action.payload;
    },
    toggleBlur: (state) => {
      state.isBlurVisible = !state.isBlurVisible;
    },
    updateRequiredDataStatus: (state, { payload }: PayloadAction<RequiredDataStatusType>) => {
      state.requiredDataStatus = payload;
    },
    updateLoadingProgress: (state, { payload }: PayloadAction<Record<'actionsCount', number>>) => {
      state.initLoadingProgress += 100 / payload.actionsCount;
    },
    clearLoadingProcess(state, { payload }: PayloadAction<number>) {
      state.initLoadingProgress = payload;
    },
  },
});

export default appSlice.reducer;

export const {
  toggleLanguage,
  setApiError,
  toggleBlur,
  updateRequiredDataStatus,
} = appSlice.actions;
