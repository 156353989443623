import { Call } from '../../components/call/call';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useTranslation } from '../../hooks/useTranslation';

import './consult.page.scss';

export const ConsultPage = () => {
  const { renderTranslation } = useTranslation();
  useDocumentTitle(
    renderTranslation({
      ru: 'Заказать консультацию с наставником',
      uk: 'Замовити консультацію з наставником',
    }),
  );

  return (
    <main className='page content-page consult-page'>
      <div className='container'>
        <div className='box'>
          <div className='page__head d-flex consult-page__head'>
            <h1 className='main-title main-title--indent'>
              {renderTranslation({ ru: 'Заказать консультацию', uk: 'Замовити консультацію' })}
            </h1>
            <Call
              newClass='consult-page__call'
              callLink='https://t.me/mentors_help_admin'
              callHint={renderTranslation({
                ru: 'Написть администратору',
                uk: 'Написати адміністратору',
              })}
              callText={renderTranslation({ ru: 'Написать нам!', uk: 'Написати нам!' })}
            />
          </div>

          <div className='content consult-page__content'>
            <h2 className='sr-only'>
              {renderTranslation({ ru: 'Список консультаций', uk: 'Список консультацій' })}
            </h2>

            <ul className='consult-list'>
              <li className='consult-list__item consult-list__item--review'>
                <h3 className='consult-list__title'>
                  {renderTranslation({ ru: 'Код-ревью проекта', uk: "Код-рев'ю проекту" })}
                </h3>
                <p className='consult-list__content'>
                  {renderTranslation({
                    ru:
                      'Видеоразбор вашей вёрстки до мельчайших деталей + ответы на вопросы после ревью',
                    uk:
                      'Відеорозбір вашої верстки до найдрібніших деталей + відповіді на запитання після рев`ю',
                  })}
                </p>
              </li>
              <li className='consult-list__item consult-list__item--support'>
                <h3 className='consult-list__title'>
                  {renderTranslation({
                    ru: 'Индивидуальная поддержка',
                    uk: 'Індивідуальна підтримка',
                  })}
                </h3>
                <p className='consult-list__content'>
                  {renderTranslation({
                    ru: 'Нужен наставник на определенный срок или конкретный курс?',
                    uk: 'Потрібен наставник на певний термін чи конкретний курс?',
                  })}
                </p>
              </li>
              <li className='consult-list__item consult-list__item--consult'>
                <h3 className='consult-list__title'>
                  {renderTranslation({ ru: 'Разовая консультация', uk: 'Разова консультація' })}
                </h3>
                <p className='consult-list__content'>
                  {renderTranslation({
                    ru: 'Срочно нужен совет / помощь / консультация',
                    uk: 'Терміново потрібна порада / допомога / консультація',
                  })}
                </p>
              </li>
              <li className='consult-list__item consult-list__item--interview'>
                <h3 className='consult-list__title'>
                  {renderTranslation({
                    ru: 'Тестовое собеседование',
                    uk: 'Тестова співбесіда',
                  })}
                </h3>
                <p className='consult-list__content'>
                  {renderTranslation({
                    ru: 'Проведем тестовое собеседование по вопросам вёрстки',
                    uk: 'Проведемо тестову співбесіду з питань верстки',
                  })}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
};
