import { Modal } from '@mantine/core';
import { useEffect } from 'react';

type Props = {
  text: string;
  show: boolean;
  onClose: () => void;
  closeDelay: number;
};

export const AutoCloseModal = ({ text, show, onClose, closeDelay }: Props) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => onClose(), closeDelay);
    }
  }, [show]);

  return (
    <Modal centered withCloseButton={false} opened={show} onClose={onClose}>
      {text}
    </Modal>
  );
};
