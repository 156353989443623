type Props = {
  id: string;
  text: string;
  value: boolean;
  onChange: () => void;
};
export const CriteriaSettingsCheckbox = ({ id, text, value, onChange }: Props) => {
  return (
    <div className='main-checkbox main-checkbox--md criteria-settings__group'>
      <input
        className='main-checkbox__checkbox  criteria-settings__checkbox sr-only'
        id={id}
        type='checkbox'
        name='settings'
        checked={value}
        onChange={onChange}
      />
      <label className='main-checkbox__label criteria-settings__label' htmlFor={id}>
        {text}
      </label>
    </div>
  );
};
