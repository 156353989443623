import './progress-bar.scss';

export const ProgressBar = (props: any) => {
  const { percentage, color } = props;

  return (
    <div className={`progress-bar ${color ? `progress-bar--${color}` : ''}`}>
      <label className='progress-bar__value' htmlFor='projectProgress'>
        {percentage}%
      </label>
      <progress
        className='progress-bar__bar'
        id='projectProgress'
        max='100'
        value={percentage}></progress>
    </div>
  );
};
