import { createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../app/app-api';
import { StudyClassDto } from '../../../openapi';

const getAllStudyClassesEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint('study-class'),
};

export const studyClassApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStudyClasses: builder.query<StudyClassDto[], void>({
      query: () => ({
        url: getAllStudyClassesEndpoint.path,
        method: getAllStudyClassesEndpoint.method,
      }),
    }),
  }),
});

export const { useGetAllStudyClassesQuery } = studyClassApi;
