import { useMemo } from 'react';
import { CriteriaProgressItemDto, CriterionDto } from '../../../openapi';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { highlightCriterion } from '../../../features/criteria/redux/criteria-slice';
import { lessonStatusIsDone, mapCategoryToTitle, normalizeArray } from '../../../shared';
import { useTranslation } from '../../../hooks/useTranslation';
import { useCurrentUser } from '../../../hooks/redux/useCurrentUser';
import { CriterionNumberItem } from './criterion-number-item';

import '../criteria-group.scss';

const scrollOffset = 200;

type Props = {
  category: CriterionDto.category;
  criteria: CriterionDto[];
};

export const ProjectNavCriteriaCategory = ({ criteria, category }: Props) => {
  const dispatch = useAppDispatch();
  const { currentLanguage } = useTranslation();
  const { currentUserProject } = useCurrentUser();
  const normalizedUserProgress = useMemo(
    () =>
      normalizeArray<CriteriaProgressItemDto>(currentUserProject.criteriaProgress, 'criterionId'),
    [currentUserProject],
  );

  const onClickHandler = (id: string) => {
    window.scrollTo({
      top:
        window.pageYOffset +
        (document.getElementById(id)?.getBoundingClientRect().top ?? 0) -
        scrollOffset,
      left: 0,
      behavior: 'smooth',
    });

    dispatch(highlightCriterion(id));
  };

  return (
    <div className='criteria-nav__group'>
      <h3 className='criteria-nav__title'>{mapCategoryToTitle(category, currentLanguage)}</h3>
      <ul className='criteria-group'>
        {criteria.map((criterion) => (
          <CriterionNumberItem
            criterionId={criterion.id}
            criterionNumber={criterion.number}
            category={category}
            isDone={lessonStatusIsDone(normalizedUserProgress[criterion.id]?.status)}
            onClick={onClickHandler}
            key={criterion.id}
          />
        ))}
      </ul>
    </div>
  );
};
