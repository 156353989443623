import { CriterionDto } from '../../../openapi';
import { mapCategoryToTitle } from '../../../shared';
import { useTranslation } from '../../../hooks/useTranslation';

type Props = {
  children: JSX.Element;
  category: CriterionDto.category;
};
export const CriteriaListLayout = ({ category, children }: Props) => {
  const { currentLanguage } = useTranslation();

  const title = mapCategoryToTitle(category, currentLanguage);

  return (
    <div className={`criteria ${category}`}>
      <section className='criteria__box'>
        <h2 className='criteria__title'>{title}</h2>
        {children}
      </section>
    </div>
  );
};
