import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getShowDoneCriteriaFromStorage,
  getShowRelevantCriteriaFromStorage,
} from '../../../shared';
import { State } from './types';

const initialState: State = {
  highlightedCriterionId: null,
  showRelevantCriteria: getShowRelevantCriteriaFromStorage() ?? false,
  showDoneCriteria: getShowDoneCriteriaFromStorage() ?? true,
};

const criteriaSlice = createSlice({
  name: 'criteria',
  initialState,
  reducers: {
    highlightCriterion: (state, action: PayloadAction<string>) => {
      state.highlightedCriterionId = action.payload;
    },
    toggleShowRelevantCriteria: (state) => {
      state.showRelevantCriteria = !state.showRelevantCriteria;
    },
    toggleShowDoneCriteria: (state) => {
      state.showDoneCriteria = !state.showDoneCriteria;
    },
  },
});

export const {
  highlightCriterion,
  toggleShowDoneCriteria,
  toggleShowRelevantCriteria,
} = criteriaSlice.actions;

export default criteriaSlice.reducer;
