import { useEffect } from 'react';
import { useAuth } from '../useAuth';
import { useAppDispatch } from '../useAppDispatch';
import { store } from '../../store';
import { criteriaApi } from '../../features/criteria/redux/criteria-api';
import { userApi } from '../../features/user/redux/user-api';
import { projectsApi } from '../../features/projects/redux/projects-api';
import { appSlice, updateRequiredDataStatus } from '../../features/app/app-slice';
import { useAppSelector } from '../useAppSelector';
import { studyClassApi } from '../../features/study-class/redux/study-class-api';

let initStarted = 0;

export const useRequiredDataLoader = () => {
  const dispatch = useAppDispatch();
  const requiredDataStatus = useAppSelector((state) => state.app.requiredDataStatus);

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn && requiredDataStatus === 'unset' && !initStarted) {
      ++initStarted;

      dispatch(updateRequiredDataStatus('loading'));

      const promises = [
        store.dispatch(userApi.endpoints.getCurrentUser.initiate()),
        store.dispatch(criteriaApi.endpoints.getAllCriteria.initiate()),
        store.dispatch(projectsApi.endpoints.getUsersProjects.initiate()),
        store.dispatch(studyClassApi.endpoints.getAllStudyClasses.initiate()),
      ];

      const handleUpdateStatus = () => {
        dispatch(appSlice.actions.updateLoadingProgress({ actionsCount: promises.length }));
      };

      promises.forEach((promise) => {
        promise.finally(handleUpdateStatus);
      });

      Promise.allSettled(promises).then(() => {
        dispatch(updateRequiredDataStatus('ready'));
      });
    }
  }, [isLoggedIn]);
};
