import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { ArticleCard } from '../../components/article-card/article-card';

import taskFirst from '../../assets/content-covers/tasks/task-1.jpg';
import taskSecond from '../../assets/content-covers/tasks/task-2.jpg';
import taskThird from '../../assets/content-covers/tasks/task-3.jpg';
import taskFourth from '../../assets/content-covers/tasks/task-4.jpg';
import taskFifth from '../../assets/content-covers/tasks/task-5.jpg';
import taskSixth from '../../assets/content-covers/tasks/task-6.jpg';
import { useTranslation } from '../../hooks/useTranslation';

import './tasks.page.scss';

export const TasksPage = () => {
  const { renderTranslation } = useTranslation();

  useDocumentTitle(renderTranslation({ ru: 'Дополнительные задания', uk: 'Додаткові завдання' }));

  return (
    <main className='page tasks-page'>
      <div className='container'>
        <section className='box'>
          <div className='page__head'>
            <h1 className='main-title main-title--indent'>
              {renderTranslation({ ru: 'Задания', uk: 'Завдання' })}
            </h1>
            <p className='main-title main-title--md'>
              {renderTranslation({
                ru: 'Здесь можно выбрать дополнительное задание для практики',
                uk: 'Тут можна вибрати додаткове завдання для практики',
              })}
            </p>
          </div>

          <div className='tasks-page__content'>
            <ul className='tasks-list'>
              <li className='tasks-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Учим семантику. Правильная разметка страницы',
                    uk: 'Вчимо семантику. Правильна розмітка сторінки',
                  })}
                  cardImage={taskFirst}
                  cardLink='https://f0t1.notion.site/e4c9ee10b1d34280afc572286bad522d'
                  cardLabel='task'
                  cardLevel='light'
                />
              </li>
              <li className='tasks-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Простая раскладка CSS. Учимся расставлять блоки на странице',
                    uk: 'Проста CSS розкладка. Вчимося розставляти блоки на сторінці',
                  })}
                  cardImage={taskSecond}
                  cardLink='https://f0t1.notion.site/70fe68645d254a02ae5c251d5be11fd0'
                  cardLabel='task'
                  cardLevel='light'
                />
              </li>
              <li className='tasks-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Прокачка навыков: position, transform, translate и другие',
                    uk: 'Прокачування навичок: position, transform, translate та інші',
                  })}
                  cardImage={taskThird}
                  cardLink='https://f0t1.notion.site/a102bca15d18421b968b6e87d8b0164a'
                  cardLabel='task'
                  cardLevel='normal'
                />
              </li>
              <li className='tasks-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Базовые компоненты. Стилизация кнопок и их состояний',
                    uk: 'Основні компоненти. Стилізація кнопок та їх станів',
                  })}
                  cardImage={taskFourth}
                  cardLink='https://f0t1.notion.site/1-4-a603332aa79049c4a0e8bece0cf3de55'
                  cardLabel='task'
                  cardLevel='normal'
                />
              </li>
              <li className='tasks-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Базовые компоненты. Стилизация слайдера и навигации',
                    uk: 'Основні компоненти. Стилізація слайдера та навігації',
                  })}
                  cardImage={taskFifth}
                  cardLink='https://f0t1.notion.site/2-5-39c1ddac0a7c4b91a2920b19386be3ad'
                  cardLabel='task'
                  cardLevel='normal'
                />
              </li>
              <li className='tasks-list__item'>
                <ArticleCard
                  cardName={renderTranslation({
                    ru: 'Базовые компоненты. Стилизация карточки товара',
                    uk: 'Основні компоненти. Стилізація картки товару',
                  })}
                  cardImage={taskSixth}
                  cardLink='https://f0t1.notion.site/3-6-a55b7b481e3a4c4ea063b69f1a6a54d2'
                  cardLabel='task'
                  cardLevel='normal'
                />
              </li>
            </ul>
          </div>
        </section>
      </div>
    </main>
  );
};
