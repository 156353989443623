import { useMemo } from 'react';
import { useAppSelector } from '../useAppSelector';
import { ProjectDto, ProjectLessonDto } from '../../openapi';
import { useUserProjects } from './useUserProjects';

type ReturnValue = {
  currentLesson: ProjectLessonDto;
  currentProject: ProjectDto;
};

export const useCurrentProjectNav = (): ReturnValue => {
  const { normalizedProjects: projects } = useUserProjects();
  const { selectedLessonNumber, selectedProjectId } = useAppSelector((state) => state.projects);

  const currentProject = useMemo(() => projects[selectedProjectId], [selectedProjectId]);

  const currentLesson = useMemo(
    () => currentProject.lessons.find((lesson) => lesson.number === selectedLessonNumber)!,
    [selectedLessonNumber],
  );

  return {
    currentLesson,
    currentProject,
  };
};
