import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationRoutes } from '../../shared';
import { useTranslation } from '../../hooks/useTranslation';

export const FormNav = () => {
  const { renderTranslation } = useTranslation();

  return (
    <>
      <div className='main-form__nav form-nav'>
        <NavLink to={NavigationRoutes.login} className='form-nav__item'>
          {renderTranslation({ ru: 'Вход', uk: 'Вхід' })}
        </NavLink>
        <NavLink to={NavigationRoutes.register} className='form-nav__item'>
          {renderTranslation({ ru: 'Регистрация', uk: 'Реєстрація' })}
        </NavLink>
      </div>
    </>
  );
};
