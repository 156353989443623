import { defaultPasswordErrors, PasswordErrors } from '../shared';
import { PasswordStatusDto } from '../openapi';
import { useApi } from './useApi.hook';

export const usePasswordValidation = () => {
  const { handleRequest, isLoading, UserApi } = useApi();

  const validate = async (password: string, confirmPassword: string): Promise<PasswordErrors> => {
    const errorsState: PasswordErrors = {
      valid: true,
      cyrillic: { ...defaultPasswordErrors.cyrillic, valid: true },
      duplicate: { ...defaultPasswordErrors.duplicate, valid: true },
      length: { ...defaultPasswordErrors.length, valid: true },
      passwordConfirmation: { ...defaultPasswordErrors.passwordConfirmation, valid: true },
    };

    const response = await handleRequest(
      UserApi.userControllerValidatePasswordChange({ requestBody: { password } }),
    );

    if (password !== confirmPassword) {
      errorsState.passwordConfirmation.valid = false;
      errorsState.valid = false;
    }

    if (!response) return { ...errorsState, valid: false };

    if (!response.errors && response.status !== PasswordStatusDto.status.SUCCESS)
      return errorsState;

    if (response.errors) {
      errorsState.valid = false;

      if (response.errors.includes(PasswordStatusDto.errors.LENGTH)) {
        errorsState.length.valid = false;
      }
      if (response.errors.includes(PasswordStatusDto.errors.CYRILLIC)) {
        errorsState.cyrillic.valid = false;
      }
      if (response.errors.includes(PasswordStatusDto.errors.DUPLICATE)) {
        errorsState.duplicate.valid = false;
      }
    }

    return errorsState;
  };

  return { isValidationLoading: isLoading, validate };
};
