import { useEffect, useState } from 'react';
import { useApi } from './useApi.hook';

export const useUserPassword = () => {
  const { UserApi, isLoading, handleRequest } = useApi();

  const [password, setPassword] = useState('');

  useEffect(() => {
    fetchPassword();
  }, []);

  const fetchPassword = async () => {
    const response = await handleRequest(UserApi.userControllerGetPassword());

    const newPassword = response?.password ?? '';
    setPassword(newPassword);

    return newPassword;
  };

  return { isLoading, password, refreshPassword: fetchPassword };
};
