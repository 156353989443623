import { CurrentUserDto, ProjectDto } from '../../../openapi';
import { formatDate, lessonStatusIsDone, Normalized } from '../../../shared';
import { useTranslation } from '../../../hooks/useTranslation';
import { useUserProjects } from '../../../hooks/projects/useUserProjects';
import { useCurrentUser } from '../../../hooks/redux/useCurrentUser';
import { StatsItem } from './stats-item';

import './profile-stats.scss';

export const ProfileStats = () => {
  const { currentUser } = useCurrentUser();
  const { renderTranslation } = useTranslation();
  const { normalizedProjects: projects } = useUserProjects();
  const registrationDate = currentUser ? new Date(currentUser.createdAt) : new Date();

  const completedProjects = getCompletedProjectsCount(currentUser, projects);

  return (
    <div className='profile-stats'>
      <ul className='profile-stats__list'>
        <StatsItem
          title={renderTranslation({ uk: 'Дата реєстрації:', ru: 'Дата регистрации:' })}
          value={formatDate(registrationDate)}
          classModifier={'profile-stats__title--date profile-stats__item--date'}
        />
        <StatsItem
          title={renderTranslation({ uk: 'Курсів:', ru: 'Курсов:' })}
          value={'1'}
          classModifier={'profile-stats__title--courses'}
        />
        <StatsItem
          title={renderTranslation({ uk: 'Завершено', ru: 'Завершено' })}
          value={String(currentUser?.finishedCourses ?? 0)}
          classModifier={'profile-stats__title--finished'}
        />
        <StatsItem
          title={renderTranslation({ uk: 'Проєктів', ru: 'Проектов' })}
          value={String(currentUser?.projects.length ?? 0)}
          classModifier={'profile-stats__title--projects'}
        />
        <StatsItem
          title={renderTranslation({ uk: 'Виконано', ru: 'Выполнено' })}
          value={String(completedProjects)}
          classModifier={'profile-stats__title--done'}
        />
      </ul>
    </div>
  );
};

function getCompletedProjectsCount(
  user: CurrentUserDto | null,
  projects: Normalized<ProjectDto>,
): number {
  return (user?.projects ?? [])
    .map((userProject) => {
      const projectDto = projects[userProject.projectId];

      const lastLessonNumber = Math.max(...projectDto.lessons.map((lesson) => lesson.number));

      return userProject.lessonsReviews.find(
        (review) => review.lessonNumber === lastLessonNumber && lessonStatusIsDone(review.status),
      );
    })
    .filter(Boolean).length;
}
