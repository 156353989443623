import { RequirementCriteriaDto } from '../../../openapi';

type Props = {
  category: RequirementCriteriaDto.category;
  criterionNumber: number;
  criterionId: string;
  isDone: boolean;
  onClick: (id: string) => void;
};

export const CriterionNumberItem = ({
  criterionNumber,
  criterionId,
  category,
  isDone,
  onClick,
}: Props) => {
  const text = `${category[0].toUpperCase()}-${criterionNumber}`;

  return (
    <li className='criteria-group__item'>
      <button
        className={`btn btn--sm btn--white criteria-group__btn ${isDone ? 'marked-done' : ''}`}
        onClick={() => onClick(criterionId)}>
        {text}
      </button>
    </li>
  );
};
